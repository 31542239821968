import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { DealsRouteParams } from '../../Deals';
import { DealsTabType } from '../../../../types/deals/DealsTabType';
import { ClassInfo } from './sections/ClassInfo';
import { Information } from './sections/Information';
import { Dates } from './sections/Dates';
import { Parties } from './sections/Parties';
import { TrancheStatus } from '../../../../types/amr-pipeline/enums/TrancheStatus';
import { DealsClassHeader } from '../../DealsClassHeader';
import { Currency } from '../../../../types/enums/Currency';
import { useFirstClassNavigation } from '../../useFirstClassNavigation';
import { user } from '../../../../user';
import { roles, routes } from '../../../../constants';
import { TransactionStatus } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { EmptyPlaceholder } from '../../../common';

export const Overview = ({ dealReferenceName, classReferenceName }: DealsRouteParams) => {
    const { selectedDeal, data } = useSelector<AppState, DealsState>(state => state.deals);
    const isAdminOrDataEntry = user.hasRoles(
        roles.Administrator,
        roles.DataEntry
    );

    const dealClasses = isAdminOrDataEntry
        ? selectedDeal?.classes
        : selectedDeal?.classes.filter(c => c.status !== TrancheStatus.Draft);
    const selectedClass = dealClasses?.find(c => c.referenceName === classReferenceName);

    useFirstClassNavigation(
        dealReferenceName,
        DealsTabType.Overview,
        data,
        selectedDeal,
        selectedClass
    );

    if (!selectedDeal) {
        return null;
    }

    const hasEmptyContent =
        !isAdminOrDataEntry &&
        !selectedDeal.transactions?.some(
            (t) => t.status === TransactionStatus.Closed
        );

    const renderEmptyPlaceHolder = () => {
        const transactionUrl = selectedDeal.transactions?.length
            ? routes.transactionDetailUrl(
                selectedDeal.transactions[0].referenceName,
                selectedDeal.referenceName
            )
            : null;
        return (
            <EmptyPlaceholder
                text={
                    <div>
                        Information will be available after the first transaction closing. <br />
                        {transactionUrl && (
                            <>
                                <a
                                    href={transactionUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Click here
                                </a>{" "}
                                to view transaction details.
                            </>
                        )}
                    </div>
                }
            />
        );
    };

    return (
        <div className="tabs-content container-flex">
            <DealsClassHeader tab={DealsTabType.Overview} />
            <div className="tabs-data tabs-data-padding tabs-data-overview">
                {hasEmptyContent ? (
                    renderEmptyPlaceHolder()
                ) : (
                    <>
                        <div className="data-item-row-wrap">
                            <div className="data-item-row-col data-item-row-col-left">
                                <Information deal={selectedDeal} />
                                <Dates deal={selectedDeal} />
                            </div>
                            <div className="data-item-row-col data-item-row-col-right">
                                {selectedClass && (
                                    <ClassInfo
                                        selectedClass={selectedClass}
                                        closingDate={selectedDeal.closingDate}
                                        dealCurrency={
                                            selectedDeal?.currencyCode as Currency
                                        }
                                    />
                                )}
                                <Parties deal={selectedDeal} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
