import { constants, roles } from '../../../../../constants';
import { Tranche } from '../../../../../types/amr-pipeline/models/Tranche';
import { amrFormatUtils, formatUtils, moneyUtils } from '../../../../../utils';
import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Currency } from '../../../../../types/enums/Currency';
import { ShowFor } from '../../../../access';

interface ClassInfoProps {
    selectedClass: Tranche;
    dealCurrency?: Currency;
    closingDate?: Date;
}

const { emptyPlaceholder } = constants;

export const ClassInfo = ({ selectedClass, dealCurrency, closingDate }: ClassInfoProps) => {
    const {
        ticker144A,
        cusiP144A,
        isiN144A,
        tickerRegS,
        cusipRegS,
        isinRegS,
        commonCodeRegS,
        tickerAccdInvCertif,
        cusipAccdInvCertif,
        isinAccdInvCertif,
        originalBalance,
        balance,
        originalMargin,
        margin,
        floaterIndex,
        minimumDenomination,
        factor,
        type,
        equity,
        redeemed,
        amr,
        nonAmrPeriod,
        nonAmrPeriodEnd,
        originalRatingMoodys,
        originalRatingSnP,
        originalRatingFitch,
        originalRatingKbra,
        originalRatingDbrs,
        ratingMoodys,
        ratingSnP,
        ratingFitch,
        ratingKbra,
        ratingDbrs,
        pariPassuTranches,
        trancheSupported,
        status,
        transactionReferenceName,
        name,
        linkedOtcName ,
    } = selectedClass;

    const { formatDate } = formatUtils;

    return (
        <SectionContent title={`Class ${name} Info`}>
            <div className="data-item-row-wrap">
                <div className="data-item-row-col">
                    <FormField
                        title="Ticker (Rule 144A)"
                        value={ticker144A ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="CUSIP (Rule 144A)"
                        value={cusiP144A ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="ISIN (Rule 144A)"
                        value={isiN144A ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Ticker (Reg S)"
                        value={tickerRegS ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="CUSIP (Reg S)"
                        value={cusipRegS ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="ISIN (Reg S)"
                        value={isinRegS ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Common Code (Reg S)"
                        value={commonCodeRegS ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Ticker (Acc'd Inv/Certif.)"
                        value={tickerAccdInvCertif ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="CUSIP (Acc'd Inv/Certif.)"
                        value={cusipAccdInvCertif ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="ISIN (Acc'd Inv/Certif.)"
                        value={isinAccdInvCertif ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Orig.Balance"
                        value={originalBalance ? moneyUtils.money(originalBalance) : emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Current Balance"
                        value={balance ? moneyUtils.money(balance) : emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Orig./Cur. Margin %"
                        value={`${originalMargin ? formatUtils.formatMargin(originalMargin) : emptyPlaceholder} / ${margin ? formatUtils.formatMargin(margin) : emptyPlaceholder
                            }`}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Floater Index"
                        value={floaterIndex ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Coupon"
                        value={amrFormatUtils.formatInventoryCoupon(floaterIndex, margin) ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                </div>
                <div className="data-item-row-col">
                    <FormField
                        title="Min. Denomination"
                        value={minimumDenomination ? moneyUtils.money(minimumDenomination) : emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Factor"
                        value={factor ? formatUtils.formatFactor(factor) : emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Type"
                        value={type ?? emptyPlaceholder}
                        tooltip={type || ""}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Equity"
                        value={formatUtils.formatBoolean(equity)}
                        className="data-item-row-full-height data-item-row-title-md text-capitalize"
                    />
                    <FormField
                        title="Redeemed"
                        value={formatUtils.formatBooleanWithPlaceholder(redeemed)}
                        className="data-item-row-full-height data-item-row-title-md text-capitalize"
                    />
                    <FormField
                        title="AMR Class"
                        value={formatUtils.formatBoolean(amr)}
                        className="data-item-row-full-height data-item-row-title-md text-capitalize"
                    />
                    <FormField
                        title="Non-AMR Period, Months"
                        value={nonAmrPeriod || emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Non-AMR Period End"
                        value={formatDate(nonAmrPeriodEnd) ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Orig. Rtg (M/S/F/K/D)"
                        value={`${originalRatingMoodys ?? emptyPlaceholder}/${originalRatingSnP ?? emptyPlaceholder}/${originalRatingFitch ?? emptyPlaceholder
                            }/${originalRatingKbra ?? emptyPlaceholder}/${originalRatingDbrs ?? emptyPlaceholder}`}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Cur. Rtg (M/S/F/K/D)"
                        value={`${ratingMoodys ?? emptyPlaceholder}/${ratingSnP ?? emptyPlaceholder}/${ratingFitch ?? emptyPlaceholder
                            }/${ratingKbra ?? emptyPlaceholder}/${ratingDbrs ?? emptyPlaceholder}`}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Currency"
                        value={dealCurrency ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Closing Date"
                        value={formatDate(closingDate) ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Pari-Passu Classes"
                        value={pariPassuTranches ?? emptyPlaceholder}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <FormField
                        title="Class Supported"
                        value={trancheSupported ?? emptyPlaceholder}
                        tooltip={trancheSupported || ""}
                        className="data-item-row-full-height data-item-row-title-md"
                    />
                    <ShowFor roles={roles.getWithout(roles.dealsAccess(), [...roles.bd(), ...roles.seller(), roles.Media])}>
                        <FormField
                            title="Status"
                            value={status ?? emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Trans. Reference & Class"
                            value={`${transactionReferenceName ?? emptyPlaceholder}, ${linkedOtcName ?? emptyPlaceholder}`}
                            tooltip={`${transactionReferenceName ?? emptyPlaceholder}, ${linkedOtcName ?? emptyPlaceholder}`}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </ShowFor>
                </div>
            </div>
        </SectionContent>
    );
};
