import { CompanyWithMembers } from '../../../../types/amr-pipeline/models/Company';
import { FormField } from '../../../amrPipeline/common/FormField';

interface Props {
    company?: CompanyWithMembers;
}

export const CompanyTab = ({ company }: Props) => {
    const renderAddress = () => {
        if (!company) return null;

        const { address, city, state, zipCode, country } = company;

        return (
            <address>
                <p>{address}</p>
                <p>{city?.name}, {state?.name}</p>
                <p>{zipCode}, {country?.name}</p>
            </address>
        );
    };

    return <FormField className="data-item-row-full-height data-item-row-title-md" title="Address" value={renderAddress()} />;
};
