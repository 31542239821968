import { FileUploader } from "../../../../amrPipeline/common/documents/FileUploader";
import { EditDocumentsList } from "./EditDocumentsList";
import { DocumentGroup } from "../../../../amrPipeline/common/documents/DocumentGroup";
import { AmrFile } from "../../../../../types/amr-pipeline/models/AmrFile";
import { DownloadFile } from "../../../../../types/amr-pipeline/models/DownloadedDocument";
import { Nullable } from "../../../../../types/primitives/Nullable";

const maxDealDocSize = 50;

interface Props<TArgs extends any[]> {
    title: string;
    dealReferenceName: string;
    items: AmrFile[];
    documentKey: string;
    withTypeColumn?: boolean;
    withPublicColumn?: boolean;
    disabledDate?: boolean;
    downloadAllFetch: (...args: TArgs) => Promise<DownloadFile>;
    downloadAllArgs: TArgs;
    fileNameForSave: Nullable<string>;
    onUpload: (documents: AmrFile[]) => void;
    setUploadProgress: (isUploading: boolean) => void;
}

export const EditDocumentsSection = <TArgs extends any[]>({
    title,
    dealReferenceName,
    items,
    documentKey,
    withTypeColumn,
    withPublicColumn,
    disabledDate = false,
    downloadAllFetch,
    downloadAllArgs,
    fileNameForSave,
    onUpload,
    setUploadProgress,
}: Props<TArgs>) => {
    return (
        <DocumentGroup
            title={title}
            itemsCount={items.filter((i) => !i.remove).length}
            downloadAllFetch={downloadAllFetch}
            downloadAllArgs={downloadAllArgs}
            fileNameForSave={fileNameForSave}
        >
            <FileUploader
                referenceName={dealReferenceName}
                documents={items}
                maxFileSizeInMb={maxDealDocSize}
                onChange={onUpload}
                onUploading={setUploadProgress}
            >
                {(props) => (
                    <EditDocumentsList
                        {...props}
                        documentKey={documentKey}
                        disabledDate={disabledDate}
                        withTypeColumn={withTypeColumn}
                        withPublicColumn={withPublicColumn}
                    />
                )}
            </FileUploader>
        </DocumentGroup>
    );
};

