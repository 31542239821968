import { useSelector } from 'react-redux';
import { SORT, roles, routes } from '../../../../constants';
import { Table } from '../../../bidding/common/table';
import { DealsDefaultHeader } from '../../DealsDefaultHeader';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { getAllClassesColumns } from './all-classes.columns';
import { Checkbox } from '../../../controls';
import { useMemo, useState } from 'react';
import { TrancheStatus } from '../../../../types/amr-pipeline/enums/TrancheStatus';
import { user } from '../../../../user';
import { Tranche } from '../../../../types/amr-pipeline/models/Tranche';

export const AllClassesView = () => {
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);
    const [showHistoricalClasses, setShowHistoricalClasses] = useState(true);

    const isCheckboxVisible = useMemo(() => {
        if (!selectedDeal) {
            return false;
        }

        return !(
            selectedDeal.classes.every(c => c.status === TrancheStatus.Inactive || c.status === TrancheStatus.Draft) ||
            !selectedDeal.classes.some(c => c.status === TrancheStatus.Inactive)
        );
    }, [selectedDeal]);

    const classes = useMemo(() => {
        if (!selectedDeal) {
            return [];
        }

        return showHistoricalClasses
            ? selectedDeal.classes
            : selectedDeal.classes.filter(tranche => tranche.status === TrancheStatus.Active);
    }, [selectedDeal, showHistoricalClasses]);

    if (!selectedDeal) {
        return null;
    }

    const editButtonUrl = user.hasRoles(roles.Administrator, roles.DataEntry)
        ? routes.dealsAllClasesUrl(selectedDeal.referenceName, true)
        : undefined;

    return (
        <>
            <DealsDefaultHeader editButtonUrl={editButtonUrl} />
            <div className="tabs-data tabs-data-padding tabs-data-all-classes">
                {isCheckboxVisible && (
                    <Checkbox
                        checked={showHistoricalClasses}
                        onChange={e => setShowHistoricalClasses(e.target.checked)}
                        label="Show Historical Classes"
                        className="flex-align-end show-historical-classes-checkbox"
                    />
                )}
                <Table
                    dataItems={classes}
                    createSecurityCustomArgs={() => ({
                        deal: selectedDeal,
                    })}
                    columns={getAllClassesColumns()}
                    sticky
                    defaultSortBy="transactionClosingDate"
                    defaultSortByDirection={SORT.DESC}
                    createSecurityCustomClassName={(c: Tranche) => {
                        if (c.status === TrancheStatus.Inactive || c.status === TrancheStatus.Draft) {
                            return 'inactive';
                        }
                    }}
                />
            </div>
        </>
    );
};
