import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { FormField } from '../../../amrPipeline/common/FormField';
import { formatUtils, isRequesting, moneyUtils, numericUtils } from '../../../../utils';
import { AmrTransaction } from '../../../../types/amr-pipeline/models/AmrTransaction';
import { constants, routes } from '../../../../constants';
import StatusLabel from '../../../amrPipeline/StatusLabel';
import { useHistory, useParams } from 'react-router-dom';
import { AmrClass } from '../../../../types/amr-pipeline/models/AmrClass';
import { AmrClassStatusLabel } from '../../../amrPipeline/common/AmrClassStatusLabel';
import { isNil, startCase } from 'lodash';
import { OnHoverTooltip, Preloader } from '../../../common';
import IconSVG from '../../../../styles/svg-icons';
import { DealsRouteParams } from '../../Deals';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { dealsActions } from '../../../../actions/deals.actions';
import { useAvailableBrokerDealers } from '../../../../effects/data-accessors/useAvailableBrokerDealers';
import AmrInfoHeader from './AmrInfoHeader';
import { useExcludedDealers } from '../../../../effects/useExcludedDealers';
import { useSettlementInstructions } from '../../../../effects/useSettlementInstructions';

const renderEmptyDealersPlaceholder = (category: string) => (
    <div className="available-excluded-dealers-row available-excluded-dealers-row-empty">
        {`There are no ${category} dealers to display`}
    </div>
);

export const AmrClassDetails = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { transactionReferenceName, classReferenceName } = useParams<DealsRouteParams>();

    const selectedTransaction = selectedDeal?.transactions?.find(
        t => t.referenceName === transactionReferenceName,
    ) as AmrTransaction;
    const selectedClass = selectedTransaction?.classes?.find(c => c.referenceName === classReferenceName) as AmrClass;

    const { requestState: settlementInstructionsRequestState, settlementInstructions } = useSettlementInstructions({
        dealReferenceName: selectedClass?.dealReferenceName,
        transactionReferenceName: selectedClass?.transactionReferenceName,
        classReferenceName: selectedClass?.referenceName,
    });
    const { requestState: availableBDRequestState, items: availableDealers } = useAvailableBrokerDealers();
    const { requestState: excludedBDRequestState, excludedDealers } = useExcludedDealers({
        dealReferenceName: selectedClass?.dealReferenceName,
        transactionReferenceName: selectedClass?.transactionReferenceName,
        classReferenceName: selectedClass?.referenceName,
    });

    if (!selectedDeal || !selectedTransaction) {
        return null;
    }

    if (!selectedClass) {
        history.push(routes.dealAmrInfoUrl(selectedDeal.referenceName, transactionReferenceName));
    }

    if (isRequesting(settlementInstructionsRequestState, availableBDRequestState, excludedBDRequestState)) {
        return <Preloader inProgress />;
    }

    const renderAvailableDealers = () => {
        if (availableDealers.length === excludedDealers.length) {
            return renderEmptyDealersPlaceholder('available');
        }

        return availableDealers.map(d =>
            excludedDealers.some(ed => ed.dealer.referenceName === d.referenceName) ? null : (
                <div key={d.referenceName} className="available-excluded-dealers-row">
                    {d.legalName}
                </div>
            ),
        );
    }

    const renderExcludedDealers = () => {
        if (!excludedDealers.length) {
            return renderEmptyDealersPlaceholder('excluded');
        }

        return excludedDealers.map(d => (
            <div key={d.dealer.referenceName} className="available-excluded-dealers-row">
                {d.dealer.legalName}
            </div>
        ));
    }

    return (
        <div className="tabs-content container-flex">
            <AmrInfoHeader />
            <div className="tabs-data tabs-data-padding tabs-data-amr-info">
                <SectionContent title={`Class ${selectedClass.name} Info`} className="data-item-general-cols">
                    <div className="data-item-row-col">
                        <FormField
                            title="Cur. Margin, %"
                            value={
                                selectedClass.margin
                                    ? formatUtils.formatMargin(selectedClass.margin)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Cap. Margin, %"
                            value={
                                selectedClass.capMargin
                                    ? formatUtils.formatMargin(selectedClass.capMargin)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Max Amount"
                            value={
                                !isNil(selectedClass.balance) && numericUtils.isNumber(selectedClass.balance)
                                    ? moneyUtils.money(selectedClass.balance)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Min Amount"
                            value={
                                !isNil(selectedClass.minBidAmount) && numericUtils.isNumber(selectedClass.minBidAmount)
                                    ? moneyUtils.money(selectedClass.minBidAmount)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Step Amount"
                            value={
                                !isNil(selectedClass.principalAmountStep) &&
                                numericUtils.isNumber(selectedClass.principalAmountStep)
                                    ? moneyUtils.money(selectedClass.principalAmountStep)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <FormField
                            title="Redemption Price"
                            value={
                                selectedClass.redemptionPrice
                                    ? formatUtils.formatDecimal(selectedClass.redemptionPrice, 6)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Non AMR Period, Month"
                            value={selectedClass.nonAmrPeriod ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="AMR Status"
                            value={<StatusLabel status={startCase(selectedTransaction.amrStatus)} />}
                            className="data-item-row-full-height data-item-row-title-md data-item-row-status"
                        />
                        <FormField
                            title="AMR Status Details"
                            value={startCase(selectedTransaction.amrStatusReason)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Auction Status"
                            value={<AmrClassStatusLabel status={selectedClass.status} />}
                            className="data-item-row-full-height data-item-row-title-md data-item-row-status"
                        />
                        <FormField
                            title="Auction Status Details"
                            value={startCase(selectedClass.statusReason)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                </SectionContent>
                <SectionContent title="Dates" className="data-item-general-cols">
                    <div className="data-item-row-col">
                        <FormField
                            title="Election Notice"
                            value={formatUtils.formatDate(selectedTransaction.electionNotice.noticeDate)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Information Notice"
                            value={formatUtils.formatDate(selectedTransaction.informationNoticeDate)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="AMR Pricing"
                            value={
                                selectedClass.pricingDate
                                    ? formatUtils.formatDate(selectedClass.pricingDate)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Submission End"
                            value={
                                selectedClass.submissionDeadline
                                    ? formatUtils.formatDate(selectedClass.submissionDeadline, constants.dateTimeFormat)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Submission Correct. End"
                            value={
                                selectedClass.submissionErrorCorrectionDeadline
                                    ? formatUtils.formatDate(
                                          selectedClass.submissionErrorCorrectionDeadline,
                                          constants.dateTimeFormat,
                                      )
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Allocation Correct. End"
                            value={
                                selectedClass.allocationErrorCorrectionDeadline
                                    ? formatUtils.formatDate(
                                          selectedClass.allocationErrorCorrectionDeadline,
                                          constants.dateTimeFormat,
                                      )
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <FormField
                            title="Mandatory Tender"
                            value={
                                selectedClass.mandatoryTenderNoticeDate
                                    ? formatUtils.formatDate(selectedClass.mandatoryTenderNoticeDate)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Amortization Notice"
                            value={
                                selectedClass.amortizationNoticeDate
                                    ? formatUtils.formatDate(selectedClass.amortizationNoticeDate)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="AMR Settlement Date"
                            value={
                                selectedClass.settlementDate
                                    ? formatUtils.formatDate(selectedClass.settlementDate)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Funding Deadline"
                            value={
                                selectedClass.fundingDeadline
                                    ? formatUtils.formatDate(selectedClass.fundingDeadline, constants.dateTimeFormat)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Delayed Funding Deadline"
                            value={
                                selectedClass.delayedFundingDeadline
                                    ? formatUtils.formatDate(
                                          selectedClass.delayedFundingDeadline,
                                          constants.dateTimeFormat,
                                      )
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Settlement Deadline"
                            value={
                                selectedClass.settlementDeadline
                                    ? formatUtils.formatDate(selectedClass.settlementDeadline, constants.dateTimeFormat)
                                    : constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                </SectionContent>
                <SectionContent
                    actionBtn={
                        <OnHoverTooltip overlay="Download settlement instructions.pdf">
                            <button
                                onClick={() => {
                                    dispatch(
                                        dealsActions.exportSettlementInstructions(
                                            selectedClass.dealReferenceName,
                                            selectedClass.dealLegalName,
                                            selectedClass.transactionReferenceName,
                                            selectedClass.referenceName,
                                            selectedClass.name,
                                        ),
                                    );
                                }}
                                className="btn-link flex-item-right"
                            >
                                <IconSVG name="export" width="16" height="16" />
                                <span className="align-middle">Export</span>
                            </button>
                        </OnHoverTooltip>
                    }
                    title="Settlement Instructions"
                    className="data-item-general-cols amr-info-settlement-instructions"
                >
                    <div className="data-item-row-col">
                        <h5>Cash Settlement Account</h5>
                        <FormField
                            title="Bank"
                            value={settlementInstructions?.cashSettlementAccountBank ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="ABA Number"
                            value={settlementInstructions?.cashSettlementAccountAbaNumber ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="SWIFT"
                            value={settlementInstructions?.cashSettlementAccountSWIFT ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Account Number"
                            value={
                                settlementInstructions?.cashSettlementAccountAccountNumber ?? constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Account Name"
                            value={
                                settlementInstructions?.cashSettlementAccountAccountName ?? constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            title="Reference"
                            value={settlementInstructions?.cashSettlementAccountReference ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <h5>Bond Settlement Account</h5>
                        <FormField
                            title="Bank"
                            value={settlementInstructions?.bondSettlementAccountBank ?? constants.emptyPlaceholder}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            value={
                                settlementInstructions?.bondSettlementAccountDTCParticipant ??
                                constants.emptyPlaceholder
                            }
                            className="data-item-row-full-height data-item-row-title-md"
                            title="DTC Participant"
                        />
                        <FormField
                            value={
                                settlementInstructions?.bondSettlementAccountAgentNumber ?? constants.emptyPlaceholder
                            }
                            title="Agent Number"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            value={
                                settlementInstructions?.bondSettlementAccountInstitutionalId ??
                                constants.emptyPlaceholder
                            }
                            title="Institutional Id"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            value={
                                settlementInstructions?.bondSettlementAccountAccountNumber ?? constants.emptyPlaceholder
                            }
                            title="Account Number"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                        <FormField
                            value={
                                settlementInstructions?.bondSettlementAccountAccountName ?? constants.emptyPlaceholder
                            }
                            title="Account Name"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                </SectionContent>
                <h5 className="contacts-title">Contacts</h5>
                <div className="data-item-row-wrap">
                    <div className="data-item-row-col">
                        <FormField
                            value={settlementInstructions?.trusteeContact ?? constants.emptyPlaceholder}
                            title="Trustee Contact"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <FormField
                            value={settlementInstructions?.amrAgentContact ?? constants.emptyPlaceholder}
                            title="AMR Agent Contact"
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                </div>
                <div className="data-item-row-wrap">
                    <div className="data-item-row-col">
                        <SectionContent title="Available Dealers">{renderAvailableDealers()}</SectionContent>
                    </div>
                    <div className="data-item-row-col">
                        <SectionContent title="Excluded Dealers">{renderExcludedDealers()}</SectionContent>
                    </div>
                </div>
            </div>
        </div>
    );
};
