import { forwardRef, useEffect, useState } from 'react';
import { DealShortInfo } from '../../types/amr-pipeline/models/Deal';
import classNames from 'classnames';
import { Link, useHistory, useParams } from 'react-router-dom';
import { roles, routes } from '../../constants';
import { DealsTabType, DealsTabTypes } from '../../types/deals/DealsTabType';
import { DealsRouteParams } from './Deals';
import Highlighter from '../amrPipeline/common/Highlighter';
import IconSVG from '../../styles/svg-icons';
import { user } from '../../user';
import { OnHoverTooltip } from '../common';

interface DealItemProps {
    deal: DealShortInfo;
    searchTerm: string;
}

export const DealItem = forwardRef(({ deal, searchTerm }: DealItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const history = useHistory();
    const { referenceName } = deal;
    const { tab: activeTab, dealReferenceName: selectedDealReferenceName } = useParams<DealsRouteParams>();

    const isAdminOrDataEntry = user.hasRoles(roles.Administrator, roles.DataEntry);
    const withDocumentsAccess =  isAdminOrDataEntry || user.hasRoles(...roles.seller());
    const isDealSelected = referenceName === selectedDealReferenceName;
    const isTabActive = (tab: DealsTabType) => tab === activeTab && isDealSelected;

    const [isCollapsed, setIsCollapsed] = useState(!isDealSelected);

    useEffect(() => {
        if (activeTab && isTabActive(activeTab)) {
            setIsCollapsed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDealReferenceName]);

    useEffect(() => {
        setIsCollapsed(!isDealSelected);
    }, [isDealSelected, selectedDealReferenceName]);

    const handleDealClick = () => {
        setIsCollapsed(!isCollapsed);

        if (!isDealSelected) {
            history.push(routes.dealsUrl(referenceName, DealsTabType.Overview, undefined));
        }
    };

    const renderTabs = () => {
        let availableTabs = DealsTabTypes;

        if (!deal.hasClosedTransactions) {
            availableTabs = isAdminOrDataEntry ? availableTabs.filter(t => t.value === DealsTabType.DealDetail) : [];
        }

        if (!withDocumentsAccess) {
            availableTabs = availableTabs.filter(t => t.value !== DealsTabType.Documents);
        }

        return availableTabs.map(tab => {
            const { value, text } = tab;
            const isActive = isTabActive(value);

            if (value === DealsTabType.AmrInfo && !deal.hasAmrTransactions) {
                return null;
            }

            return (
                <Link
                    className={classNames('component-subtree-item', { active: isActive })}
                    to={routes.dealsUrl(referenceName, value, undefined)}
                    key={value}
                    onClick={e => {
                        isActive && e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <span className="component-subtree-item-content">
                        <span className="component-subtree-item-title">{text}</span>
                    </span>
                </Link>
            );
        });
    };

    return (
        <div ref={ref}>
            <div
                className={classNames(
                    { expanded: !isCollapsed },
                    'sidebar-nav-list-item sidebar-nav-list-item-extended',
                )}
                onClick={handleDealClick}
            >
                {/* <i className="icon icon-expand"></i> */}
                {(deal.hasClosedTransactions || isAdminOrDataEntry) && (
                    <IconSVG name="icon-expand" width={16} height={16} />
                )}
                <div className="sidebar-nav-list-item-content">
                    <OnHoverTooltip overlay={deal.legalName}>
                        <div className="title">
                            <Highlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={deal.legalName} />
                        </div>
                    </OnHoverTooltip>
                </div>
            </div>
            {!isCollapsed && <div className="component-subtree">{renderTabs()}</div>}
        </div>
    );
});
