import { Deal } from '../../types/amr-pipeline/models/Deal';
import { getCurrentIntexPositionIdForDeal } from '../../utils/amr-pipeline.utils';
import { IntexLink } from '../common/IntexLink';

interface DealIntexLinkProps {
    deal: Deal;
}

export const DealIntexLink = ({ deal }: DealIntexLinkProps) => (
    <IntexLink positionId={getCurrentIntexPositionIdForDeal(deal)} text="Intex" />
);
