import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { useParams } from 'react-router-dom';
import { DealsRouteParams } from '../../Deals';
import AmrInfoHeader from './AmrInfoHeader';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { FormField } from '../../../amrPipeline/common/FormField';
import { AmrTransaction } from '../../../../types/amr-pipeline/models/AmrTransaction';
import StatusLabel from '../../../amrPipeline/StatusLabel';
import { formatUtils } from '../../../../utils';
import { constants } from '../../../../constants/constants';
import { Table } from '../../../bidding/common/table';
import { amrClassesColumns } from './amr-classes.columns';
import { useState } from 'react';
import { AmrClass } from '../../../../types/amr-pipeline/models/AmrClass';
import { DocumentGroup } from '../../../amrPipeline/common/documents/DocumentGroup';
import { DocumentsList } from '../documents/DocumentsList';
import { Preloader } from '../../../common';
import { RequestState } from '../../../../constants/request-state';
import { SettlementInstructionsPopup } from './edit/SettlementInsctructionsPopup';
import { EditDealersPopup } from './edit/EditDealersPopup';
import { useAmrDocuments } from '../../../../effects/useAmrDocuments';
import { dealsService } from '../../../../services/deals.service';
import { AmrFile } from '../../../../types/amr-pipeline/models/AmrFile';
import saveAs from 'file-saver';
import { dealsActions } from '../../../../actions/deals.actions';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { Confirm } from '../../../alerts';
import { DownloadFile } from '../../../../types/amr-pipeline/models/DownloadedDocument';

export const AmrInfoDetails = () => {
    const dispatch = useAppDispatch();
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const [classForDealerPopup, setClassForDealerPopup] = useState<AmrClass>();
    const [classForSettlementInstructionPopup, setClassForSettlementInstructionPopup] = useState<AmrClass>();
    const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] = useState(false);


    const { transactionReferenceName } = useParams<DealsRouteParams>();

    const selectedTransaction = selectedDeal?.transactions?.find(
        t => t.referenceName === transactionReferenceName,
    ) as AmrTransaction;

    const { amrDocuments, requestState } = useAmrDocuments({
        dealReferenceName: selectedDeal?.referenceName,
        transactionReferenceName: selectedTransaction?.referenceName,
    });

    if (!selectedDeal || !selectedTransaction) {
        return null;
    }

    const deleteDraftTransaction = () => {
        dispatch(dealsActions.deleteAmrTransaction(selectedDeal.referenceName, selectedTransaction.referenceName));
    }

    const downloadDocument = async (document: AmrFile) => {
        await dealsService
            .downloadAmrDocument(selectedDeal.referenceName, selectedTransaction.referenceName, document.referenceName)
            .then((file: DownloadFile) => saveAs(file.blob, document.name));
    }

    return (
        <div className="tabs-content container-flex">
            <AmrInfoHeader onDeleteClick={() => setShowDeleteConfirmationPopup(true)} />
            <div className="tabs-data tabs-data-padding tabs-data-amr-info">
                <SectionContent title="Deal Info" className="data-item-general-cols">
                    <div className="data-item-row-col">
                        <FormField
                            title="AMR Status"
                            value={<StatusLabel status={selectedTransaction.amrStatus} />}
                            className="data-item-row-full-height data-item-row-status"
                        />
                        <FormField
                            title="Election Notice"
                            value={formatUtils.formatDate(selectedTransaction.electionNotice.noticeDate)}
                            className="data-item-row-full-height"
                        />
                        <FormField
                            title="Information Notice"
                            value={formatUtils.formatDate(selectedTransaction.informationNoticeDate)}
                            className="data-item-row-full-height"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <FormField
                            title="Election — Information, b. days"
                            value={selectedDeal.electionToInformationBusinessDays || constants.emptyPlaceholder}
                            className="data-item-row-full-height"
                            capitalize={false}
                        />
                        <FormField
                            title="Information — Pricing, b. days"
                            value={selectedDeal.informationToPricingBusinessDays || constants.emptyPlaceholder}
                            className="data-item-row-full-height"
                            capitalize={false}
                        />
                        <FormField
                            title="Pricing — Settlement, b. days"
                            value={selectedDeal.pricingToSettlementBusinessDays || constants.emptyPlaceholder}
                            className="data-item-row-full-height"
                            capitalize={false}
                        />
                    </div>
                </SectionContent>
                <SectionContent title="AMR Classes">
                    <Table
                        sticky
                        columns={amrClassesColumns}
                        dataItems={selectedTransaction.classes}
                        createSecurityCustomArgs={() => ({
                            setClassForDealerPopup,
                            setClassForSettlementInstructionPopup,
                        })}
                        className="data-list-amr-classes"
                    />
                </SectionContent>
                <Preloader inProgress={requestState === RequestState.request}>
                    <DocumentGroup
                        title="AMR Documents"
                        itemsCount={amrDocuments?.length}
                        emptyPlaceholder="There are no documents to display."
                        downloadAllFetch={dealsService.downloadAmrDocuments}
                        downloadAllArgs={[selectedDeal.referenceName, selectedTransaction.referenceName]}
                        fileNameForSave={`${selectedDeal.ticker} AMR Documents.zip`}
                    >
                        <DocumentsList
                            dealReferenceName={selectedDeal.referenceName}
                            onClick={downloadDocument}
                            withPublic
                            items={amrDocuments || []}
                        />
                    </DocumentGroup>
                </Preloader>

                {classForDealerPopup && (
                    <EditDealersPopup
                        selectedClass={classForDealerPopup}
                        onClose={() => setClassForDealerPopup(undefined)}
                    />
                )}
                {classForSettlementInstructionPopup && (
                    <SettlementInstructionsPopup
                        selectedClass={classForSettlementInstructionPopup}
                        onClose={() => setClassForSettlementInstructionPopup(undefined)}
                    />
                )}
            </div>
            {showDeleteConfirmationPopup && (
                <Confirm
                    confirmButtonText="Yes"
                    onCancel={() => setShowDeleteConfirmationPopup(false)}
                    onConfirm={deleteDraftTransaction}
                    title="Delete Confirmation"
                    text={`Are you sure you want to delete ${
                        selectedTransaction.legalNameOnDeal
                    } ${formatUtils.formatDate(selectedTransaction.closingDate)} AMR Transaction?`}
                />
            )}
        </div>
    );
};
