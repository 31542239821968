import React, { useEffect, useMemo, useState } from 'react';
import { dealsActions } from '../../../../../actions/deals.actions';
import { useAvailableBrokerDealers } from '../../../../../effects/data-accessors/useAvailableBrokerDealers';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { AmrClass } from '../../../../../types/amr-pipeline/models/AmrClass';
import { Transfer } from '../../../../common/transfer/Transfer';
import { TransferOption } from '../../../../common/transfer/types';
import { Popup, PopupBody, PopupFooter } from '../../../../controls';
import { useExcludedDealers } from '../../../../../effects/useExcludedDealers';

interface EditDealersPopupProps {
    onClose: () => void;
    selectedClass: AmrClass;
}

export const EditDealersPopup = ({ selectedClass, onClose }: EditDealersPopupProps) => {
    const dispatch = useAppDispatch();
    const [selectedDealers, setSelectedDealers] = useState<TransferOption[]>([]);

    const { items } = useAvailableBrokerDealers();
    const { excludedDealers } = useExcludedDealers({
        dealReferenceName: selectedClass.dealReferenceName,
        transactionReferenceName: selectedClass.transactionReferenceName,
        classReferenceName: selectedClass.referenceName,
    });

    const availableBrokerDealers = useMemo(() => {
        return items.map((company, key) => ({
            title: company.legalName,
            key: company.referenceName,
            fixed: false,
            permanent: false,
            available: true,
            draggable: false,
            order: key,
        }));
    }, [items]);

    useEffect(() => {
        setSelectedDealers(
            excludedDealers
                .filter(d => d.trancheReferenceName === selectedClass.referenceName)
                .map((d, key) => ({
                    title: d.dealer.legalName,
                    key: d.dealer.referenceName,
                    fixed: false,
                    permanent: false,
                    available: true,
                    draggable: false,
                    order: key,
                })),
        );
    }, [excludedDealers, selectedClass.referenceName]);

    const handleTransferChange = (options: TransferOption[]) => {
        setSelectedDealers(options);
    };

    const handleReset = () => {
        setSelectedDealers([]);
    };

    const handleSave = () => {
        dispatch(
            dealsActions.saveExcludedDealersRequest(
                selectedClass.dealReferenceName,
                selectedClass.transactionReferenceName,
                selectedClass.referenceName,
                selectedDealers.map(dealer => dealer.key),
            ),
        );
        onClose();
    };

    return (
        <Popup
            title={
                <>
                    Available / Excluded Dealers
                    <span className="name-tag text-ellipsis">Class {selectedClass.name}</span>
                </>
            }
            onClose={onClose}
            modalClass="customize-columns-popup available-excluded-dealers-popup"
        >
            <PopupBody>
                <Transfer
                    leftColumnTitle="Available Dealers"
                    rightColumnTitle="Excluded Dealers"
                    options={availableBrokerDealers}
                    selected={selectedDealers}
                    onChange={handleTransferChange}
                    withFixed={false}
                    destinationDraggable={false}
                    onReset={handleReset}
                />
            </PopupBody>
            <PopupFooter>
                <button type="button" className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button type="submit" className="btn btn-main" onClick={handleSave}>
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
};
