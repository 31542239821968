import { capitalize } from "lodash";
import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { formatUtils } from '../../../../../utils';
import { renderMarkdownOrEmpty } from "../../../../common/markdown-editor";

interface Props {
    deal: Deal;
}

export const AdditionalInformation = ({ deal }: Props) => {
    const {
        euCompliance,
        euRiskRetentionRequirements,
        euDisclosureRequirements,
        esg,
        esgComment,
        staticDeal,
        amrDeal,
    } = deal;

    return (
        <SectionContent title="Additional Information">
            <FormField
                title="EU Compliance"
                value={capitalize(formatUtils.formatBoolean(euCompliance))}
                className="data-item-row-full-height"
            />
            <FormField
                title="EU Risk Retention Requirements"
                className="data-item-row-full-height data-item-row-details"
            >
                <div className="data-item-text">
                    {renderMarkdownOrEmpty(euRiskRetentionRequirements)}
                </div>
            </FormField>
            <FormField
                title="EU Disclosure Requirements"
                className="data-item-row-full-height data-item-row-details"
            >
                <div className="data-item-text">
                    {renderMarkdownOrEmpty(euDisclosureRequirements)}
                </div>
            </FormField>
            <FormField
                title="ESG"
                value={capitalize(formatUtils.formatBoolean(esg))}
                className="data-item-row-full-height"
            />
            <FormField
                title="ESG Comment"
                className="data-item-row-full-height data-item-row-details"
            >
                <div className="data-item-text">
                    {renderMarkdownOrEmpty(esgComment)}
                </div>
            </FormField>
            <FormField
                title="Static Deal"
                value={capitalize(formatUtils.formatBoolean(staticDeal))}
                className="data-item-row-full-height"
            />
            <FormField
                title="Deal With AMR"
                value={capitalize(formatUtils.formatBoolean(amrDeal))}
                className="data-item-row-full-height"
            />
        </SectionContent>
    );
};
