import { Link } from 'react-router-dom';
import { AmrClass } from '../../../../types/amr-pipeline/models/AmrClass';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { constants, routes } from '../../../../constants';
import { AmrClassStatusLabel } from '../../../amrPipeline/common/AmrClassStatusLabel';
import { isNil } from 'lodash';
import { numericUtils, moneyUtils, formatUtils } from '../../../../utils';
import { OnHoverTooltip } from '../../../common';
import { NonAmrPeriod } from '../../../amrPipeline/common/NonAmrPeriod';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import IconSVG from '../../../../styles/svg-icons';

const amrClassesColumnsDefinition: IColumnDefinition<AmrClass>[] = [
    {
        columnKey: 'name',
        renderColumnHeaderContent: () => 'Class',
        renderColumnContent: amrClass => (
            <Link
                to={routes.dealAmrInfoClassUrl(
                    amrClass.dealReferenceName,
                    amrClass.transactionReferenceName,
                    amrClass.referenceName,
                )}
            >
                {amrClass.name}
            </Link>
        ),
        stickLeft: true,
    },
    {
        columnKey: 'status',
        renderColumnHeaderContent: () => 'Auction Status',
        renderColumnContent: amrClass => <AmrClassStatusLabel status={amrClass.status} />,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'balance',
        renderColumnHeaderContent: () => 'Cur. Bal',
        renderColumnContent: amrClass =>
            !isNil(amrClass.balance) && numericUtils.isNumber(amrClass.balance)
                ? moneyUtils.money(amrClass.balance)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'margin',
        renderColumnHeaderContent: () => 'Cur. Margin, %',
        renderColumnContent: amrClass =>
            amrClass.margin ? formatUtils.formatMargin(amrClass.margin) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'capMargin',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={'Maximum margin which was accepted for the auction'} placement="bottomRight">
                <span>Cap Margin, %</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: amrClass =>
            amrClass.capMargin ? formatUtils.formatMargin(amrClass.capMargin) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'maxAmount',
        renderColumnHeaderContent: () => 'Max Amount',
        renderColumnContent: amrClass =>
            !isNil(amrClass.balance) && numericUtils.isNumber(amrClass.balance)
                ? moneyUtils.money(amrClass.balance)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'minAmount',
        renderColumnHeaderContent: () => 'Min Amount',
        renderColumnContent: amrClass =>
            !isNil(amrClass.minBidAmount) && numericUtils.isNumber(amrClass.minBidAmount)
                ? moneyUtils.money(amrClass.minBidAmount)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'stepAmount',
        renderColumnHeaderContent: () => 'Step Amount',
        renderColumnContent: amrClass =>
            !isNil(amrClass.principalAmountStep) && numericUtils.isNumber(amrClass.principalAmountStep)
                ? moneyUtils.money(amrClass.principalAmountStep)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'redemption',
        renderColumnHeaderContent: () => 'Redemption Price',
        renderColumnContent: amrClass =>
            amrClass.redemptionPriceInPercent
                ? formatUtils.formatDecimal(amrClass.redemptionPriceInPercent, 6)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-md text-right'
    },
    {
        columnKey: 'pricingDate',
        renderColumnHeaderContent: () => 'AMR Pricing',
        renderColumnContent: amrClass =>
            amrClass.pricingDate ? formatUtils.formatDate(amrClass.pricingDate) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'settlementDate',
        renderColumnHeaderContent: () => 'AMR Settlement',
        renderColumnContent: amrClass =>
            amrClass.settlementDate ? formatUtils.formatDate(amrClass.settlementDate) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'nonAmr',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={'Number of months before another AMR can be started'} placement="bottomRight">
                <span>Non-AMR</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: amrClass => <NonAmrPeriod period={amrClass.nonAmrPeriod} />,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'actions',
        renderColumnHeaderContent: () => 'Actions',
        renderColumnContent: (amrClass, { setClassForDealerPopup, setClassForSettlementInstructionPopup }) => (
            <>
                <button className="btn-link regular" onClick={() => setClassForSettlementInstructionPopup(amrClass)}>
                    <IconSVG name="edit-pencil" width={16} height={16} />
                    Edit Set. Ins.
                </button>
                <button className="btn-link regular" onClick={() => setClassForDealerPopup(amrClass)}>
                    <IconSVG name="edit-pencil" width={16} height={16} />
                    Edit Dealers
                </button>
            </>
        ),
        className: 'data-list-cell-xl data-list-cell-amr-classes-actions text-right',
        stickRight: true,
    },
];

export const amrClassesColumns = amrClassesColumnsDefinition.map(column => new ColumnBuilder(column));
