import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { Dates } from '../overview/sections/Dates';
import { Information } from '../overview/sections/Information';
import { Parties } from '../overview/sections/Parties';
import { AdditionalInformation } from './sections/Additional Information';
import { ManagementFee } from './sections/ManagementFee';
import { AdministrativeFee } from './sections/AdministrativeFee';
import { DealAmendmentDetails } from './sections/DealAmendmentDetails';
import { DealsDefaultHeader } from '../../DealsDefaultHeader';
import { roles, routes } from '../../../../constants';
import { DealsTabType } from '../../../../types/deals/DealsTabType';
import { useContext } from 'react';
import DealsContext from '../../DealsContext';
import { DealStatus } from '../../../../types/amr-pipeline/enums/DealStatus';
import { user } from '../../../../user/user';
import { ShowFor } from '../../../access';

export const DealDetail = () => {
    const { setShowDeleteConfirmationPopup } = useContext(DealsContext);
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const isEditAvailable = user.hasRoles(...roles.dealsEdit());

    if (!selectedDeal) {
        return null;
    }

    return (
        <div className="tabs-content container-flex">
            <DealsDefaultHeader
                editButtonUrl={
                    isEditAvailable
                        ? routes.editDealUrl(selectedDeal.referenceName, DealsTabType.DealDetail)
                        : undefined
                }
                onDeleteClick={
                    isEditAvailable && selectedDeal.status === DealStatus.Draft
                        ? () => setShowDeleteConfirmationPopup(true)
                        : undefined
                }
            />
            <div className="tabs-data tabs-data-padding">
                <div className="data-item-row-wrap">
                    <div className="data-item-row-col">
                        <Information deal={selectedDeal} />
                    </div>
                    <div className="data-item-row-col">
                        <Dates deal={selectedDeal} />
                    </div>
                </div>
                <Parties deal={selectedDeal} />
                <AdditionalInformation deal={selectedDeal} />
                <ShowFor roles={roles.getAllRolesExclude(...roles.bd())}>
                    <div className="data-item-row-wrap">
                        <div className="data-item-row-col">
                            <ManagementFee deal={selectedDeal} />
                        </div>
                        <div className="data-item-row-col">
                            <AdministrativeFee deal={selectedDeal} />
                        </div>
                    </div>
                </ShowFor>
                <DealAmendmentDetails deal={selectedDeal} />
            </div>
        </div>
    );
};
