import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { FormField } from '../../../amrPipeline/common/FormField';
import { formatUtils, moneyUtils, numericUtils } from '../../../../utils';
import { Table } from '../../../bidding/common/table';
import { AmrTransaction } from '../../../../types/amr-pipeline/models/AmrTransaction';
import { TransactionType } from '../../../../types/amr-pipeline/enums/TransactionType';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { SORTING_TYPE, constants, roles, routes } from '../../../../constants';
import StatusLabel from '../../../amrPipeline/StatusLabel';
import { transactionStatusTitles } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { Link } from 'react-router-dom';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { AmrClass } from '../../../../types/amr-pipeline/models/AmrClass';
import { AmrClassStatusLabel } from '../../../amrPipeline/common/AmrClassStatusLabel';
import { isNil } from 'lodash';
import { OnHoverTooltip } from '../../../common';
import { NonAmrPeriod } from '../../../amrPipeline/common/NonAmrPeriod';
import IconSVG from '../../../../styles/svg-icons';
import { DealsDefaultHeader } from '../../DealsDefaultHeader';
import { user } from '../../../../user/user';
import { TableColumnStickType } from '../../../bidding/common/table/types/TableColumnStickType';

const actionsColumn: IColumnDefinition<AmrTransaction, {}> = {
    columnKey: 'actions',
    renderColumnHeaderContent: () => 'Actions',
    renderColumnContent: transaction => (
        <Link
            to={routes.dealAmrInfoUrl(transaction.dealReferenceName, transaction.referenceName)}
            className="btn-link regular"
        >
            <IconSVG name="go-to" width={16} height={16} />
            View / Edit AMR
        </Link>
    ),
    className: 'data-list-cell-md data-list-cell-action text-right',
    stickRight: true,
};

const transactionColumnsDefinition: IColumnDefinition<AmrTransaction, {}>[] = [
    {
        columnKey: 'lastUpdated',
        renderColumnHeaderContent: () => 'Last Updated',
        renderColumnContent: transaction => formatUtils.formatDate(transaction.lastUpdated),
        sortingField: 'lastUpdated',
        sortingType: SORTING_TYPE.date,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'status',
        renderColumnHeaderContent: () => 'Trans. Status',
        renderColumnContent: transaction => <StatusLabel status={transactionStatusTitles[transaction.status]} />,
        sortingField: 'status',
        className: 'data-list-cell-sm-01',
        sortingType: SORTING_TYPE.transactionStatus
    },
    {
        columnKey: 'settlementDate',
        renderColumnHeaderContent: () => 'AMR Settlement',
        renderColumnContent: transaction => formatUtils.formatDate(transaction.closingDate),
        sortingField: 'closingDate',
        sortingType: SORTING_TYPE.date,
        className: 'data-list-cell-auto',
    },
];
const classesColumnsDefinition: IColumnDefinition<AmrClass, {}>[] = [
    {
        columnKey: 'status',
        renderColumnHeaderContent: () => 'Auction Status',
        renderColumnContent: amrClass => <AmrClassStatusLabel status={amrClass.status} />,
        className: 'data-list-cell-lg',
    },
    {
        columnKey: 'class',
        renderColumnHeaderContent: () => 'Class',
        renderColumnContent: amrClass => amrClass.name,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'cusip',
        renderColumnHeaderContent: () => 'CUSIP (144A)',
        renderColumnContent: amrClass => amrClass.cusip,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Cur. Rtg (M/S/F/K/D)',
        renderColumnContent: amrClass =>
            `${amrClass.ratingMoodys || constants.emptyPlaceholder}/${
                amrClass.ratingSnP || constants.emptyPlaceholder
            }/${amrClass.ratingFitch || constants.emptyPlaceholder}/${
                amrClass.ratingKbra || constants.emptyPlaceholder
            }/${amrClass.ratingDbrs || constants.emptyPlaceholder}`,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'balance',
        renderColumnHeaderContent: () => 'Cur. Bal',
        renderColumnContent: amrClass =>
            !isNil(amrClass.balance) && numericUtils.isNumber(amrClass.balance)
                ? moneyUtils.money(amrClass.balance)
                : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'totalBids',
        renderColumnHeaderContent: () => 'Total Bids',
        renderColumnContent: amrClass =>
            amrClass.totalOfBids ? moneyUtils.money(amrClass.totalOfBids) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm text-right',
    },
    {
        columnKey: 'winningMargin',
        renderColumnHeaderContent: () => 'Win Margin, %',
        renderColumnContent: amrClass =>
            amrClass.winningMargin ? formatUtils.formatMargin(amrClass.winningMargin) : constants.emptyPlaceholder,
        className: 'data-list-cell-sm-01 text-right',
    },
    {
        columnKey: 'capMargin',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={'Maximum margin which was accepted for the auction'} placement="bottomRight">
                <span>Cap Margin, %</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: amrClass =>
            amrClass.capMargin ? formatUtils.formatMargin(amrClass.capMargin) : constants.emptyPlaceholder,
        className: 'data-list-cell-md text-right',
    },
    {
        columnKey: 'pricingDate',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={'Date when the new margin is determined'}>
                <span>AMR Pricing</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: amrClass => formatUtils.formatDate(amrClass.pricingDate),
        className: 'data-list-cell-sm-01',
    },
    {
        columnKey: 'nonAmr',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay={'Number of months before another AMR can be started'} placement="bottomRight">
                <span>Non-AMR</span>
            </OnHoverTooltip>
        ),
        renderColumnContent: amrClass => <NonAmrPeriod period={amrClass.nonAmrPeriod} />,
        className: 'data-list-cell-sm-01',
    },
];

const transactionColumns = transactionColumnsDefinition.map(c => new ColumnBuilder(c));
const classesColumns = classesColumnsDefinition.map(c => new ColumnBuilder(c));

export const AmrInfo = () => {
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const amrTransactions = selectedDeal?.transactions?.filter(t => t.type === TransactionType.amr) || [];

    const isAdminOrDataEntryOrMedia = user.hasRoles(roles.Administrator, roles.DataEntry);

    if (!selectedDeal) {
        return null;
    }

    const renderCollapsibleItem = (transaction: AmrTransaction, columnType: TableColumnStickType) => {
        let columns = classesColumns;

        if (columnType === TableColumnStickType.Right) {
            columns = [
                new ColumnBuilder({
                    columnKey: 'emptyCol',
                    renderColumnHeaderContent: () => '',
                    renderColumnContent: () => '',
                    className: 'data-list-cell--01',
                    stickRight: true,
                }),
            ];
        }

        return <Table columns={columns} dataItems={transaction.classes} defaultSortBy="settlementDate" />;
    };

    return (
        <div className="tabs-content container-flex">
            <DealsDefaultHeader />
            <div className="tabs-data tabs-data-padding">
                <div className="data-item-row-wrap">
                    <div className="data-item-row-col">
                        <FormField
                            title="Last AMR Pricing"
                            value={formatUtils.formatDate(selectedDeal.lastAmrPricingDate)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                    <div className="data-item-row-col">
                        <FormField
                            title="Last AMR Settlement"
                            value={formatUtils.formatDate(selectedDeal.lastAmrSettlementDate)}
                            className="data-item-row-full-height data-item-row-title-md"
                        />
                    </div>
                </div>

                <Table
                    columns={
                        isAdminOrDataEntryOrMedia
                            ? [...transactionColumns, new ColumnBuilder(actionsColumn)]
                            : transactionColumns
                    }
                    dataItems={amrTransactions}
                    defaultSortBy="settlementDate"
                    collapsible
                    sticky={isAdminOrDataEntryOrMedia}
                    renderCollapsibleItem={renderCollapsibleItem}
                    className="data-list-striped data-list-deals"
                />
            </div>
        </div>
    );
};
