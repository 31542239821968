import React from 'react';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import IconSVG from '../../../../../styles/svg-icons';
import { Link } from 'react-router-dom';
import { SORT, SORTING_TYPE, constants, routes } from '../../../../../constants';
import { Transaction } from '../../../../../types/amr-pipeline/models/Transaction';
import { formatUtils } from '../../../../../utils';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { Table } from '../../../../bidding/common/table';
import { startCase } from 'lodash';
import { EmptyPlaceholder, OnHoverTooltip } from '../../../../common';

interface Props {
    deal: Deal;
}

const dealAmendmentDetailsColumnsDefinition: IColumnDefinition<
    Transaction,
    { dealReferenceName: string; previousTransaction?: Transaction }
>[] = [
    {
        columnKey: 'type',
        renderColumnHeaderContent: () => 'Type',
        renderColumnContent: transaction => startCase(transaction.type),
        sortingField: 'type',
    },
    {
        columnKey: 'link',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (transaction, { dealReferenceName }) => {
            return (
                <OnHoverTooltip overlay="See on Issuance Monitor">
                    <Link
                        target="_blank"
                        to={routes.transactionDetailUrl(transaction.referenceName, dealReferenceName)}
                        className="btn-link"
                    >
                        <IconSVG name="go-to-details" width={16} height={16} />
                    </Link>
                </OnHoverTooltip>
            );
        },
        className: 'data-list-cell-xxs',
    },
    {
        columnKey: 'closingDate',
        renderColumnHeaderContent: () => 'Closing',
        renderColumnContent: transaction =>
            transaction.closingDate ? formatUtils.formatDate(transaction.closingDate) : constants.emptyPlaceholder,
        sortingField: 'closingDate',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'riEnd',
        renderColumnHeaderContent: () => 'Previous / Amended RIE',
        renderColumnContent: (transaction, { previousTransaction }) =>
            `${
                previousTransaction?.reinvestmentPeriodEnd
                    ? formatUtils.formatDate(previousTransaction.reinvestmentPeriodEnd)
                    : constants.emptyPlaceholder
            } / ${
                transaction.reinvestmentPeriodEnd
                    ? formatUtils.formatDate(transaction.reinvestmentPeriodEnd)
                    : constants.emptyPlaceholder
            }`,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'ncEnd',
        renderColumnHeaderContent: () => 'Previous / Amended NCE',
        renderColumnContent: (transaction, { previousTransaction }) =>
            `${
                previousTransaction?.nonCallPeriodEnd
                    ? formatUtils.formatDate(previousTransaction.nonCallPeriodEnd)
                    : constants.emptyPlaceholder
            } / ${
                transaction.nonCallPeriodEnd
                    ? formatUtils.formatDate(transaction.nonCallPeriodEnd)
                    : constants.emptyPlaceholder
            }`,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'maturity',
        renderColumnHeaderContent: () => 'Previous / Amended Maturity',
        renderColumnContent: (transaction, { previousTransaction }) =>
            `${
                previousTransaction?.statedMaturity ?? previousTransaction?.finalMaturity
                    ? formatUtils.formatDate(previousTransaction.statedMaturity ?? previousTransaction.finalMaturity)
                    : constants.emptyPlaceholder
            } / ${
                transaction.statedMaturity ?? transaction.finalMaturity
                    ? formatUtils.formatDate(transaction.statedMaturity ?? transaction.finalMaturity)
                    : constants.emptyPlaceholder
            }`,
        className: 'data-list-cell-xl',
    },
];

const dealAmendmentDetailsColumns = dealAmendmentDetailsColumnsDefinition.map(column => new ColumnBuilder(column));

export const DealAmendmentDetails = ({ deal }: Props) => {
    const transactions = deal.transactions;

    if (!transactions) {
        return null;
    }

    const findPreviousTransaction = (referenceName: string) => {
        const index = transactions.findIndex(t => t.referenceName === referenceName);

        if (index === -1) {
            return undefined;
        }

        return transactions[index + 1];
    };

    return (
        <SectionContent title="Deal Amendment Details">
            {!!transactions.length ? (
                <Table
                    columns={dealAmendmentDetailsColumns}
                    dataItems={deal.transactions}
                    defaultSortBy="closingDate"
                    defaultSortByDirection={SORT.DESC}
                    createSecurityCustomArgs={(t: Transaction, i: number) => ({
                        dealReferenceName: deal.referenceName,
                        previousTransaction: findPreviousTransaction(t.referenceName),
                    })}
                    className="data-list-striped"
                />
            ) : (
                <EmptyPlaceholder text="There is no information to display." />
            )}
        </SectionContent>
    );
};
