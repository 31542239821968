import * as React from 'react';
import * as _ from 'lodash';
import { DirectBiddingDisclaimer } from './DirectBiddingDisclaimer';
import { useDispatch, useSelector } from 'react-redux';
import { directBiddingDisclaimerActions } from '../../actions/direct-bidding-agreement-disclaimer.actions';
import { AppState } from '../../types/state/AppState';

type TRenderChildren = (blocked: boolean) => React.ReactNode;

interface Props {
    children: React.ReactNode | TRenderChildren;
    disabled?: boolean;
    onConfirm: () => void;
}

export function DirectBiddingDisclaimerActionBlocker({ children, onConfirm, disabled = false }: Props) {
    const actionBlockerId = React.useRef(_.uniqueId('direct-bidding-disclaimer-action-blocker-'));
    const dispatch = useDispatch();
    const visible = useSelector((state: AppState) => state.directBiddingDisclaimer.visible);
    const confirmed = useSelector((state: AppState) => state.directBiddingDisclaimer.confirmed);
    const blockerId = useSelector((state: AppState) => state.directBiddingDisclaimer.blockerId);
    const handleConfirm = React.useCallback(() => onConfirm(), [onConfirm]);

    React.useEffect(() => {
        if (!visible && actionBlockerId.current === blockerId) {
            if (confirmed) {
                handleConfirm();
            }
            if (confirmed || confirmed === false) {
                dispatch(directBiddingDisclaimerActions.reset());
            }
        }
    }, [visible, actionBlockerId, blockerId, confirmed, dispatch, handleConfirm]);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(directBiddingDisclaimerActions.show(actionBlockerId.current));
    };

    const renderBlocked = () => <div className="display-inline" onClick={handleClick}>{renderChildren(true)}</div>;

    const renderChildren = (blocked: boolean) => {
        if (typeof children === 'function') {
            return (children as TRenderChildren)(blocked);
        }
        return children;
    };

    if (disabled) {
        return <>{renderChildren(false)}</>;
    }

    return (
        <DirectBiddingDisclaimer>
            {(blocked: boolean) => blocked ? renderBlocked() : renderChildren(false)}
        </DirectBiddingDisclaimer>
    );
}
