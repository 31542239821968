import classnames from 'classnames';
import { SelectionPanelSearch } from '../amrPipeline/common/SelectionPanelSearch';
import { EmptyPlaceholder, Preloader } from '../common';
import { DealShortInfo } from '../../types/amr-pipeline/models/Deal';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DealItem } from './DealItem';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { dealsActions } from '../../actions/deals.actions';
import { useDebounce } from '../../effects/useDebounce';

interface Props {
    data: DealShortInfo[];
    selectedReferenceName: string;
    isLoading: boolean;
}

export const DealsSelectionPanel = ({ data, selectedReferenceName, isLoading }: Props) => {
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectedDealRef = useRef<HTMLDivElement>(null);

    const debouncedSearch = useDebounce(searchTerm, 500);

    useEffect(() => {
        var customEvent = document.createEvent('Events');
        customEvent.initEvent('resize', true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    useLayoutEffect(() => {
        if (selectedReferenceName) {
            selectedDealRef.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (debouncedSearch.length > 2 || debouncedSearch.length === 0) {
            dispatch(dealsActions.getDeals(debouncedSearch));
        }
    }, [debouncedSearch, dispatch]);

    const handleSearchTermChange = (value: string) => {
        setSearchTerm(value);
    };

    const renderDeals = () =>
        data.map(deal => {
            return <DealItem ref={deal.referenceName === selectedReferenceName ? selectedDealRef : null } key={deal.referenceName} searchTerm={searchTerm} deal={deal} />;
        });

    const renderEmptyPlaceholder = () => <EmptyPlaceholder text="No search results found." textView />;

    return (
        <aside className={classnames('sidebar sidebar-nav sidebar-nav-deals', { collapsed })}>
            <SelectionPanelSearch
                searchTerm={searchTerm}
                collapsed={collapsed}
                placeholder="Search"
                onCollapseClick={setCollapsed}
                onSearch={handleSearchTermChange}
            />

            <nav className="sidebar-nav-list sidebar-nav-list-mixed">
                <Preloader inProgress={isLoading}>{data.length ? renderDeals() : renderEmptyPlaceholder()}</Preloader>
            </nav>
        </aside>
    );
};
