import { useSelector } from 'react-redux';
import { DealsHeaderBase } from './DealsHeaderBase';
import { AppState } from '../../types/state/AppState';
import { DealsState } from '../../types/state/DealsState';
import { useParams } from 'react-router';
import { DealsRouteParams } from './Deals';
import { DealIntexLink } from './DealIntexLink';
import { RelatedTransDropdown } from '../amrPipeline/common/RelatedTransDropdown';

interface Props {
    editButtonUrl?: string;
    editTooltip?: string;
    isEditDisabled?: boolean;
    onDeleteClick?: () => void;
}

export const DealsDefaultHeader = ({ editButtonUrl, editTooltip, isEditDisabled, onDeleteClick  }: Props) => {
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { dealReferenceName } = useParams<DealsRouteParams>();

    const hasTransactions = !!selectedDeal?.transactions?.length;

    if (!selectedDeal) {
        return null;
    }

    const renderRelatedTransIcon = () => (
        <RelatedTransDropdown
            dealReferenceName={dealReferenceName}
            dealLegalName={selectedDeal.legalName}
            hasTransactions={hasTransactions}
        />
    );

    return (
        <DealsHeaderBase
            title={selectedDeal.legalName}
            dateUpdated={selectedDeal.dateUpdated}
            onDeleteClick={onDeleteClick}
            renderLinks={() => [
                renderRelatedTransIcon(),
                <DealIntexLink deal={selectedDeal} />,
            ]}
            editButtonUrl={editButtonUrl}
            editTooltip={editTooltip}
            isEditDisabled={isEditDisabled}
        />
    );
};
