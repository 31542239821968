import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { constants, routes } from '../../../../../constants';
import { useContext } from 'react';
import DealsContext from '../../../DealsContext';
import { Link } from 'react-router-dom';
import { CompanyStatus } from '../../../../../types/amr-pipeline/enums/CompanyStatus';
import { OnHoverTooltip } from '../../../../common/OnHoverTooltip';

interface Props {
    deal: Deal;
}

const { emptyPlaceholder } = constants;

export const Parties = ({ deal }: Props) => {
    const { setCompanyForPopup } = useContext(DealsContext);
    const {
        collateralManager,
        majorityEquity,
        trustee,
        amrAgent,
        originalArranger,
        currentArranger
    } = deal;

    return (
        <SectionContent title="Parties">
            <FormField
                title="Orig. Arranger"
                value={
                    originalArranger ? (
                        <Link target="_blank" to={routes.manageBanksUrl(originalArranger.referenceName)}>
                            {originalArranger.legalName}
                        </Link>
                    ) : (
                        emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Cur. Arranger"
                value={
                    currentArranger ? (
                        <Link target="_blank" to={routes.manageBanksUrl(currentArranger.referenceName)}>
                            {currentArranger.legalName}
                        </Link>
                    ) : (
                        emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Trustee"
                value={
                    trustee && trustee.status === CompanyStatus.Active ? (
                        <span className="pseudo-link" onClick={e => setCompanyForPopup(trustee.referenceName)}>
                            {trustee.legalName}
                        </span>
                    ) : (
                        deal.trustee?.legalName ? (
                            <OnHoverTooltip overlay={deal.trustee?.legalName}>
                                {deal.trustee?.legalName}
                            </OnHoverTooltip>
                        ) : emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Collateral Manager"
                value={
                    collateralManager ? (
                        <Link target="_blank" to={routes.manageCloManagerUrl(collateralManager.referenceName)}>
                            {collateralManager?.legalName}
                        </Link>
                    ) : (
                        emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="Majority Equity"
                value={
                    majorityEquity && majorityEquity.status === CompanyStatus.Active ? (
                        <span
                            className="pseudo-link"
                            onClick={e => setCompanyForPopup(majorityEquity.referenceName)}
                        >
                            {majorityEquity.legalName}
                        </span>
                    ) : (
                        majorityEquity?.legalName ? (
                            <OnHoverTooltip overlay={majorityEquity?.legalName}>
                                {majorityEquity?.legalName}
                            </OnHoverTooltip>
                        ) : emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
            <FormField
                title="AMR Agent"
                value={
                    amrAgent && amrAgent.status === CompanyStatus.Active ? (
                        <span className="pseudo-link" onClick={e => setCompanyForPopup(amrAgent.referenceName)}>
                            {amrAgent.legalName}
                        </span>
                    ) : (
                        amrAgent?.legalName ? (
                            <OnHoverTooltip overlay={amrAgent?.legalName}>
                                {amrAgent?.legalName}
                            </OnHoverTooltip>
                        ) : emptyPlaceholder
                    )
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
        </SectionContent>
    );
};
