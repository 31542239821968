import React from "react";

interface DealsContextProps {
    companyForPopup: string | null;
    setCompanyForPopup: (referenceName: string) => void;
    showDeleteConfirmationPopup: boolean;
    setShowDeleteConfirmationPopup: (show: boolean) => void;
}

const DealsContext = React.createContext<DealsContextProps>({
    companyForPopup: null,
    setCompanyForPopup: () => null,
    showDeleteConfirmationPopup: false,
    setShowDeleteConfirmationPopup: () => null,
});

export default DealsContext;
