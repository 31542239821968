import { Route, Switch } from 'react-router';
import { routes } from '../../../../constants';
import { AllClassesView } from './AllClassesView';
import { AllClassesEdit } from './AllClassesEdit';

export const AllClasses = () => {
    return (
        <div className="tabs-content container-flex">
            <Switch>
                <Route
                    exact
                    path={routes.dealsAllClasesUrl(':dealReferenceName')}
                    render={() => <AllClassesView />}
                />
                <Route
                    exact
                    path={routes.dealsAllClasesUrl(':dealReferenceName', true)}
                    render={() => <AllClassesEdit />}
                />
            </Switch>
        </div>
    );
};
