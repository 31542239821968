import { useState } from 'react';
import { CompanyTab } from './tabs/Company';
import { Contact } from '../../../types/amr-pipeline/models/Contact';
import { useCompany } from '../../../effects/data-accessors/useCompany';
import Popup from '../../controls/Popup';
import { TabList } from '../../bidding/common/tab-list/TabList';
import { TabItem } from '../../bidding/common/tab-list/TabItem';
import { sortContactsByPrimary } from '../../../utils/amr-pipeline.utils';
import MembersTab from './tabs/Members';

enum Tab {
    Members,
    Company,
}

interface Props {
    companyReferenceName: string;
    collateralManagerReferenceName?: string;
    onClose: () => void;
}

export const CompanyPopup = ({ companyReferenceName, collateralManagerReferenceName, onClose }: Props) => {
    const [tab, setTab] = useState(Tab.Members);

    const { companyWithMembers } = useCompany(companyReferenceName);

    const { members, primaryContact, legalName } = companyWithMembers || {};

    if (!companyWithMembers) {
        return null;
    }

    const renderTab = () => {
        if (tab === Tab.Company) {
            return <CompanyTab company={companyWithMembers} />;
        }

        let sortedMembers: Contact[] = [];

        if (members && members.length) {
            sortedMembers = sortContactsByPrimary(members, primaryContact);
        }

        return (
            <MembersTab
                collateralManagerReferenceName={collateralManagerReferenceName}
                members={sortedMembers}
                primaryContact={primaryContact}
            />
        );
    };

    if (!members) {
        return null;
    }

    const getMembersTitle = () => {
        let title = "Members";
        if (members.length) {
            title += ` (${members.length})`;
        }

        return title;
    };

    return (
        <Popup title={legalName} modalClass="modal-iois" onClose={onClose}>
            <div className="modal-body">
                <TabList>
                    <TabItem
                        active={tab === Tab.Members}
                        title={getMembersTitle()}
                        onClick={() => setTab(Tab.Members)}
                    />
                    <TabItem active={tab === Tab.Company} title="Company" onClick={() => setTab(Tab.Company)} />
                </TabList>
                <div className="tabs-content container-flex">{renderTab()}</div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-ghost" onClick={onClose}>Close</button>
            </div>
        </Popup>
    );
};
