import { isNil } from 'lodash';

interface DealsSaveHeaderProps {
    title: string;
    subTitle: string;
    isUpdating?: boolean;
    hasChanges?: boolean;
    onSaveClick: () => void;
    onCancelClick: () => void;
}

export const DealsSaveHeader = ({
    title,
    subTitle,
    isUpdating,
    hasChanges,
    onSaveClick,
    onCancelClick,
}: DealsSaveHeaderProps) => {
    const saveButtonDisabled = isUpdating || (!isNil(hasChanges) && !hasChanges);

    return (
        <div className="sub-header sub-header-deals">
            <div className="sub-header-row type02">
                <div className="flex-row">
                    <h1>{title}</h1>
                    <span className="name-tag">{subTitle}</span>
                    <div className="flex-item-right">
                        <button className="btn btn-ghost btn-sm" onClick={onCancelClick} disabled={isUpdating}>
                            Cancel
                        </button>
                        <button className="btn btn-main btn-sm" onClick={onSaveClick} disabled={saveButtonDisabled}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
