import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { formatUtils } from '../../../../../utils';
import moment from 'moment';
import { constants } from '../../../../../constants';

interface Props {
    deal: Deal;
}

export const Dates = ({ deal }: Props) => {
    const {
        closingDate,
        nonCallPeriodEnd,
        reinvestmentPeriodEnd,
        lastRefinancingDate,
        finalMaturity,
        firstPaymentDate,
        secondPaymentDate,
        quarterlyDay,
        quarterlyMonths,
        vintage,
        redemptionDate,
    } = deal;
    const { formatDate } = formatUtils;

    return (
        <SectionContent title="Dates">
            <FormField title="Closing Date" value={formatDate(closingDate)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Non Call End" value={formatDate(nonCallPeriodEnd)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Reinvestment End" value={formatDate(reinvestmentPeriodEnd)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Last Refinancing" value={formatDate(lastRefinancingDate)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Maturity" value={formatDate(finalMaturity)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Deal Vintage" value={vintage ? moment(vintage).year() : constants.emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="First Payment Date" value={formatDate(firstPaymentDate)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Second Payment Date" value={formatDate(secondPaymentDate)} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Quarterly Day" value={quarterlyDay || constants.emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Quarterly Months" value={quarterlyMonths || constants.emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Redemption Date" value={formatDate(redemptionDate)} className="data-item-row-full-height data-item-row-title-md" />
        </SectionContent>
    );
};
