import { useSelector } from 'react-redux';
import { DealsHeaderBase } from './DealsHeaderBase';
import { AppState } from '../../types/state/AppState';
import { DealsState } from '../../types/state/DealsState';
import { useHistory, useParams } from 'react-router';
import { DealsRouteParams } from './Deals';
import { ClassSelect } from './ClassSelect';
import { routes } from '../../constants';
import { ValitanaLink } from '../common/ValitanaLink';
import { BloombergLink } from '../common/BloombergLink';
import { DealIntexLink } from './DealIntexLink';
import { RelatedTransDropdown } from '../amrPipeline/common/RelatedTransDropdown';

interface Props {
    tab: string;
    editButtonUrl?: string;
}

export const DealsClassHeader = ({ tab, editButtonUrl }: Props) => {
    const history = useHistory();
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { dealReferenceName, classReferenceName } = useParams<DealsRouteParams>();
    const activeClass = selectedDeal?.classes.find(c => c.referenceName === classReferenceName);
    const withTransactions = !!selectedDeal?.transactions?.length;

    if (!selectedDeal) {
        return null;
    }

    const renderRelatedTransIcon = () => {
        return (
            <RelatedTransDropdown
                dealReferenceName={dealReferenceName}
                dealLegalName={selectedDeal.legalName}
                hasTransactions={withTransactions}
            />
        );
    };

    const onChangeClass = (dealClassReferenceName: string) => {
        return history.push(routes.dealsUrl(dealReferenceName, tab, dealClassReferenceName));
    }

    return (
        <DealsHeaderBase
            title={selectedDeal.legalName}
            dateUpdated={selectedDeal.dateUpdated}
            editButtonUrl={editButtonUrl}
            renderTitleSuffix={
                selectedDeal.classes.length
                    ? () => (
                          <>
                              <div className="vertical-divider" />
                              <ClassSelect
                                  selectedClassReferenceName={classReferenceName}
                                  onChange={onChangeClass}
                                  classes={selectedDeal.classes}
                              />
                          </>
                      )
                    : undefined
            }
            renderLinks={() => [
                renderRelatedTransIcon(),
                <DealIntexLink deal={selectedDeal} />,
                <BloombergLink identifiers={activeClass} textLink />,
                <ValitanaLink identifiers={activeClass} textLink />,
            ]}
        />
    );
};
