import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { formatUtils, moneyUtils } from '../../../../../utils';
import { constants } from '../../../../../constants';

interface Props {
    deal: Deal;
}

const { emptyPlaceholder } = constants;

export const AdministrativeFee = ({ deal }: Props) => {
    const { adminExpenseCap, adminExpenseCapPercentage, trusteeFee } = deal;

    return (
        <SectionContent title="Administrative Fee">
            <FormField
                title="Admin Expense Cap, bps"
                value={adminExpenseCapPercentage ? formatUtils.formatDecimal(adminExpenseCapPercentage) : emptyPlaceholder}
                className="data-item-row-full-height"
                capitalize={false}
            />
            <FormField
                title="Admin Expense Cap Percentages, $"
                value={
                    adminExpenseCap
                        ? moneyUtils.money(adminExpenseCap)
                        : emptyPlaceholder
                }
                className="data-item-row-full-height"
                capitalize={false}
            />
            <FormField
                title="Trustee, bps"
                value={trusteeFee ? formatUtils.formatDecimal(trusteeFee) : emptyPlaceholder}
                className="data-item-row-full-height"
                capitalize={false}
            />
        </SectionContent>
    );
};
