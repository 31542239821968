import { useSelector } from "react-redux";
import { AppState } from "../../../../types/state/AppState";
import { DealsState } from "../../../../types/state/DealsState";
import { DealsRouteParams } from "../../Deals";
import { DealsTabType } from "../../../../types/deals/DealsTabType";
import { ClassInfo } from "../overview/sections/ClassInfo";
import { ClassSecondaryInfo } from "./sections/ClassSecondaryInfo";
import { DealsClassHeader } from "../../DealsClassHeader";
import { useFirstClassNavigation } from "../../useFirstClassNavigation";

export const ClassDetails = ({ dealReferenceName, classReferenceName }: DealsRouteParams) => {
    const { selectedDeal, data } = useSelector<AppState, DealsState>(
        (state) => state.deals
    );
    const selectedClass = selectedDeal?.classes.find(
        (c) => c.referenceName === classReferenceName
    );

    useFirstClassNavigation(
        dealReferenceName,
        DealsTabType.ClassDetail,
        data,
        selectedDeal,
        selectedClass
    );

    if (!selectedDeal || !selectedClass) {
        return null;
    }

    return (
        <div className="tabs-content container-flex">
            <DealsClassHeader tab={DealsTabType.ClassDetail} />
            <div className="tabs-data tabs-data-padding">
                <ClassInfo
                    selectedClass={selectedClass}
                    closingDate={selectedDeal?.closingDate}
                    dealCurrency={selectedDeal?.currencyCode}
                />
                <ClassSecondaryInfo selectedClass={selectedClass} />
            </div>
        </div>
    );
};
