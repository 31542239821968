import * as React from 'react';
import * as _ from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DirectBiddingDisclaimer } from './DirectBiddingDisclaimer';
import { DirectBiddingDisclaimerContext } from './DirectBiddingDisclaimerContext';
import { AppState } from '../../types/state/AppState';
import { directBiddingDisclaimerActions } from '../../actions/direct-bidding-agreement-disclaimer.actions';

interface Props {
    disabled?: boolean;
    onCancel?: () => void;
    children: React.ReactNode;
}

interface ContentProps {
    onCancel?: () => void;
    children: React.ReactNode;
}

const DirectBiddingDisclaimerContent: React.FC<ContentProps> = ({ children, onCancel }) => {
    const actionBlockerId = React.useRef(_.uniqueId('direct-bidding-disclaimer-content-blocker-'));
    const blocked = React.useContext(DirectBiddingDisclaimerContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const popupVisible = useSelector((state: AppState) => state.directBiddingDisclaimer.visible);
    const confirmed = useSelector((state: AppState) => state.directBiddingDisclaimer.confirmed);
    const blockerId = useSelector((state: AppState) => state.directBiddingDisclaimer.blockerId);
    const handleReject = React.useCallback(() => onCancel ? onCancel() : history.replace('/'), [onCancel, history]);

    React.useEffect(() => {
        if (blocked && !popupVisible && confirmed === undefined) {
            dispatch(directBiddingDisclaimerActions.show(actionBlockerId.current))
        }
    }, [dispatch, blocked, popupVisible, actionBlockerId, confirmed]);

    React.useEffect(() => {
        if (!popupVisible && confirmed === false && actionBlockerId.current === blockerId) {
            handleReject();
            dispatch(directBiddingDisclaimerActions.reset());
        }
    }, [history, popupVisible, confirmed, actionBlockerId, blockerId, dispatch, handleReject]);

    return blocked ? null : <>{children}</>;
};

export const DirectBiddingDisclaimerContentBlocker: React.FC<Props> = ({ disabled = false, children, onCancel }) => {
    if (disabled) return <>{children}</>;

    return (
        <DirectBiddingDisclaimer>
            {() => (
                <DirectBiddingDisclaimerContent onCancel={onCancel}>
                    {children}
                </DirectBiddingDisclaimerContent>
            )}
        </DirectBiddingDisclaimer>
    );
};
