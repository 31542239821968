import { head } from "lodash";
import { useEffect } from "react";
import { DealsTabType } from "../../types/deals/DealsTabType";
import { useHistory } from "react-router";
import { Deal, DealShortInfo } from "../../types/amr-pipeline/models/Deal";
import { Tranche } from "../../types/amr-pipeline/models/Tranche";
import { routes } from "../../constants";

export const useFirstClassNavigation = (
    dealReferenceName: string,
    dealTab: DealsTabType,
    data?: DealShortInfo[],
    selectedDeal?: Deal,
    selectedClass?: Tranche,
) => {
    const history = useHistory();

    useEffect(() => {
        const firstClass = head(selectedDeal?.classes);
        if(selectedClass || !data || !firstClass) {
            return;
        }

        history.replace(
            routes.dealsUrl(
                dealReferenceName,
                dealTab,
                firstClass.referenceName
            )
        );       
    }, [selectedDeal, selectedClass, history, data, dealReferenceName, dealTab]);
};

