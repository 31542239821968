import { useEffect } from 'react';
import { Grid } from '../../../grid';
import { routes } from '../../../../constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { useAppDispatch } from '../../../../effects/useAppDispatch';
import { isEqual, isNil } from 'lodash';
import { DealsSaveHeader } from '../../DealsSaveHeader';
import { useHistory } from 'react-router';
import { dealsActions } from '../../../../actions/deals.actions';
import { RouteLeavingGuard } from '../../../routing';
import { gridUtils } from '../../../../utils';

export const AllClassesEdit = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const dataItems = useSelector((state: AppState) => state.grid.dataItems);
    const { selectedDeal, isDetailsLoading, isUpdating, allClasses } = useSelector<AppState, DealsState>(state => state.deals);

    useEffect(() => {
        return () => {
            dispatch(dealsActions.allClassesInvalidateTranceCache());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isDetailsLoading || isNil(selectedDeal)) {
            return;
        }

        dispatch(dealsActions.allClassesEditInit(selectedDeal));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDetailsLoading, isNil(selectedDeal)]);

    if (!selectedDeal) {
        return null;
    }

    const hasChanges = () => !isEqual(allClasses.classes, gridUtils.sanitizeGridItems(dataItems));

    const handleSave = () => dispatch(dealsActions.allClassesUpdate(selectedDeal.referenceName));

    const handleCancel = () => history.push(routes.dealsAllClasesUrl(selectedDeal.referenceName));

    return (
        <>
            <RouteLeavingGuard
                navigate={(pathname: string) => history.push(pathname)}
                shouldBlockNavigation={() => hasChanges() && !isUpdating}
            />
            <DealsSaveHeader
                title="Edit All Classes"
                subTitle={selectedDeal.legalName}
                onSaveClick={handleSave}
                onCancelClick={handleCancel}
                hasChanges={hasChanges() && !isUpdating}
            />
            <div className="tabs-data tabs-data-padding tabs-data-all-classes-edit">
                <Grid dataUploadDisabled />
            </div>
        </>
    );
};
