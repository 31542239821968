import * as React from 'react';
import { useHistory } from 'react-router';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { DirectBiddingDisclaimerActionBlocker } from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { FeatureLink } from '../access/FeatureLink';

interface BodyContentProps {
    path: string;
    disclaimerDisabled?: boolean;
    requiredFeature?: SubscriptionFeature;
    disabled?: boolean;
    text?: string;
    className?: string;
    target?: string;
    tooltip?: string;
}

export function DirectBiddingDesclaimerLink({
    path,
    disclaimerDisabled = false,
    requiredFeature,
    disabled = false,
    text = "View",
    target,
    className,
    tooltip
}: BodyContentProps) {
    const history = useHistory();

    return (
        <ActionBlocker feature={requiredFeature}>
            <DirectBiddingDisclaimerActionBlocker
                disabled={disclaimerDisabled || disabled}
                onConfirm={() => history.push(path)}
            >
                <FeatureLink
                    disabled={disabled}
                    className={className}
                    text={text}
                    path={path}
                    target={target}
                    tooltip={tooltip}
                />
            </DirectBiddingDisclaimerActionBlocker>
        </ActionBlocker>
    );
}
