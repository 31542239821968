import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../controls/Popup';
import { PopupBody, PopupFooter } from '../controls';
import { Checkbox } from '../controls/Checkbox';
import { directBiddingDisclaimerActions } from '../../actions/direct-bidding-agreement-disclaimer.actions';
import { DirectBiddingDisclaimerPopupText } from './DirectBiddingDisclaimerPopupText';
import { AppState } from '../../types/state/AppState';
import { isRequesting } from '../../utils';
import IconSVG from '../../styles/svg-icons';

export const DirectBiddingDisclaimerPopup: React.FC = () => {
    const dispatch = useDispatch();

    const [agreeCheckBoxChecked, setAgreeCheckBoxChecked] = useState(false);
    const downloadPdfRequestState = useSelector((state: AppState) => state.directBiddingDisclaimer.downloadPdfRequestState);
    const confirmRequestState = useSelector((state: AppState) => state.directBiddingDisclaimer.confirmRequestState);
    const visible = useSelector((state: AppState) => state.directBiddingDisclaimer.visible);

    useEffect(() => {
        if (!visible) {
            setAgreeCheckBoxChecked(false);
        }
    }, [visible]);

    if (!visible) {
        return null;
    }

    const handleOnClose = () => {
        dispatch(directBiddingDisclaimerActions.hide(false))
    };

    return (
        <Popup
            title="User Agreement"
            modalClass="modal-user-agreement"
            onClose={handleOnClose}
        >
            <PopupBody>
                <div className="disclaimer">
                    <DirectBiddingDisclaimerPopupText />
                    <div className="row">
                        <button
                            className="btn-link"
                            onClick={() => dispatch(directBiddingDisclaimerActions.downloadPdfRequest())}
                            disabled={isRequesting(downloadPdfRequestState)}
                        >
                            <IconSVG name="downloadTemplate" width={16} height={16} /> Download agreement
                        </button>
                    </div>
                    <div className="row">
                        <Checkbox
                            label="I agree to terms and conditions"
                            checked={agreeCheckBoxChecked}
                            onChange={() => setAgreeCheckBoxChecked(!agreeCheckBoxChecked)}
                        />
                    </div>
                </div>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" disabled={isRequesting(confirmRequestState)} onClick={handleOnClose}>Cancel</button>
                <button
                    className="btn btn-main"
                    onClick={() => dispatch(directBiddingDisclaimerActions.confirmRequest())}
                    disabled={!agreeCheckBoxChecked || isRequesting(confirmRequestState)}
                >
                    Agree
                </button>
            </PopupFooter>
        </Popup>
    )
};
