import { useCallback } from 'react';
import { routes } from '../../../../constants';
import { Breadcrumbs } from '../../../controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../../controls/breadcrumbs/BreadcrumbsItem';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppState } from '../../../../types/state/AppState';
import { DealsState } from '../../../../types/state/DealsState';
import { DealsRouteParams } from '../../Deals';
import { DealsTabType } from '../../../../types/deals/DealsTabType';
import { formatUtils } from '../../../../utils';
import { DealsHeaderBase } from '../../DealsHeaderBase';
import { AmrTransaction } from '../../../../types/amr-pipeline/models/AmrTransaction';
import { AmrStatus } from '../../../../types/amr-pipeline/enums/AmrStatus';

export const AmrInfoHeader = ({ onDeleteClick }: { onDeleteClick?: () => void }) => {
    const { selectedDeal } = useSelector<AppState, DealsState>(state => state.deals);

    const { classReferenceName, transactionReferenceName } = useParams<DealsRouteParams>();

    if (!selectedDeal) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getSelectedTransaction = useCallback(() => {
        return selectedDeal.transactions?.find(t => t.referenceName === transactionReferenceName) as AmrTransaction;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classReferenceName, selectedDeal.transactions]);

    const selectedClass = getSelectedTransaction().classes?.find(c => c.referenceName === classReferenceName);

    const classTitle = `Class ${selectedClass?.name}`;
    const transactionTitle = `${selectedDeal.legalName} (${formatUtils.formatDate(
        getSelectedTransaction().lastUpdated,
    )})`;

    const title = classReferenceName ? classTitle : transactionTitle;

    const handleDelete = () => {
        onDeleteClick && onDeleteClick();
    };

    return (
        <div className="amr-info-header">
            <Breadcrumbs>
                <BreadcrumbsItem
                    route={routes.dealsUrl(selectedDeal?.referenceName, DealsTabType.AmrInfo)}
                    text="AMR Info"
                />

                {classReferenceName && (
                    <>
                        <BreadcrumbsItem
                            route={routes.dealAmrInfoUrl(selectedDeal.referenceName, transactionReferenceName)}
                            text={transactionTitle}
                        />
                    </>
                )}
            </Breadcrumbs>
            <DealsHeaderBase
                title={title}
                dateUpdated={selectedDeal.dateUpdated}
                onDeleteClick={
                    !classReferenceName && getSelectedTransaction().amrStatus === AmrStatus.Draft
                        ? handleDelete
                        : undefined
                }
                editButtonUrl={
                    !classReferenceName
                        ? routes.dealAmrInfoEditUrl(selectedDeal.referenceName, transactionReferenceName)
                        : undefined
                }
            />
        </div>
    );
};

export default AmrInfoHeader;
