import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { formatUtils } from '../../../../../utils';
import { constants } from '../../../../../constants';

interface Props {
    deal: Deal;
}

const { emptyPlaceholder } = constants;

export const ManagementFee = ({ deal }: Props) => {
    const { seniorManagementFee, incentiveManagementFee, subManagementFee } = deal;

    return (
        <SectionContent title="Management Fee">
            <FormField
                title="Senior Management Fee, bps"
                value={seniorManagementFee ? formatUtils.formatDecimal(seniorManagementFee) : emptyPlaceholder}
                className="data-item-row-full-height"
                capitalize={false}
            />
            <FormField
                title="Sub Management Fee, bps"
                value={subManagementFee ? formatUtils.formatDecimal(subManagementFee) : emptyPlaceholder}
                className="data-item-row-full-height"
                capitalize={false}
            />
            <FormField
                title="Incentive Management Fee, bps"
                value={incentiveManagementFee ? formatUtils.formatDecimal(incentiveManagementFee) : emptyPlaceholder}
                className="data-item-row-full-height"
                capitalize={false}
            />
        </SectionContent>
    );
};
