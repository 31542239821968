import { SectionContent } from "../../../../amrPipeline/common/section/SectionContent";
import { Tranche } from "../../../../../types/amr-pipeline/models/Tranche";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dealsActions } from "../../../../../actions/deals.actions";
import { useInvenotory } from "../../../../../effects/data-accessors/useInventory";
import { AppState } from "../../../../../types/state/AppState";
import { isRequestNone, isRequesting } from "../../../../../utils";
import { SecurityHistory } from "../../../../security-details/SecurityHistory";
import { PxTalkHistoricalColorDispersion } from "../../../../security-details/PxTalkHistoricalColorDispersion";
import { EmptyPlaceholder, Preloader } from "../../../../common";
import { DealsState } from "../../../../../types/state/DealsState";

interface Props {
    selectedClass: Tranche;
}

export const ClassSecondaryInfo = ({ selectedClass }: Props) => {
    const { classSecurity, classSecurityStats, isSecondaryInfoLoading } =
        useSelector<AppState, DealsState>((state) => state.deals);
    const isInventoryDisabled = classSecurity == null;
    const inventoryState = useInvenotory(
        isInventoryDisabled,
        [],
        classSecurity?.ticker || ""
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(dealsActions.getClassSecondaryInfo(selectedClass));
    }, [dispatch, selectedClass]);

    const isLoading =
        isSecondaryInfoLoading ||
        (!isInventoryDisabled &&
            (isRequestNone(inventoryState.requestState) ||
                isRequesting(inventoryState.requestState))
            );

    const withHistoryData =
        classSecurity &&
        (classSecurityStats.count || inventoryState.inventory.length);

    return (
        <SectionContent title={`Class ${selectedClass.name} Secondary Info`}>
            <div className="security-information">
                <Preloader inProgress={isLoading}>
                    {withHistoryData ? (
                        <>
                            <PxTalkHistoricalColorDispersion
                                securityStatistics={classSecurityStats}
                                securityId={classSecurity.id}
                                evalPriceTalkVisible
                            />
                            <SecurityHistory
                                securityIdentifier={classSecurity.ticker}
                                bwicsHistory={classSecurityStats}
                                inventory={inventoryState.inventory}
                            />
                        </>
                    ) : (
                        <EmptyPlaceholder text="There is no information to display" />
                    )}
                </Preloader>
            </div>
        </SectionContent>
    );
};

