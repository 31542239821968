import React, { useMemo } from 'react';
import { user } from '../../user';
import { useSettlementAgentAgreements } from '../../effects/useSettlementAgentAgreements';
import { SettlementAgreementStatus } from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { BlockedFeatureContext } from '../access/BlockedFeatureContext';
import { DirectBiddingDisclaimerContext } from './DirectBiddingDisclaimerContext';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { isRequesting } from '../../utils';
import { roles } from '../../constants';

interface Props {
    children: (blocked: boolean) => React.ReactNode;
}

export const DirectBiddingDisclaimer: React.FC<Props> = ({ children }) => {
    const isFeatureBlocked = React.useContext(BlockedFeatureContext);
    const agreementsState = useSettlementAgentAgreements();
    const agreements = agreementsState.items;
    const confirmRequestState = useSelector((state: AppState) => state.directBiddingDisclaimer.confirmRequestState);

    const hasConfirmedClearingBankAgreement = useMemo(() =>
        agreements.some(a =>
            a.agreementStatus === SettlementAgreementStatus.confirmed &&
            a.settlementAgent.isClearingBank),
        [agreements]
    );

    const blocked =
        !isFeatureBlocked &&
        hasConfirmedClearingBankAgreement &&
        user.hasRoles(roles.SellerTrader, roles.BrokerDealerTrader) &&
        !user.current()?.directBiddingAgreementSigned &&
        !isRequesting(confirmRequestState);

    return (
        <DirectBiddingDisclaimerContext.Provider value={blocked}>
            {children(blocked)}
        </DirectBiddingDisclaimerContext.Provider>
    )
}
