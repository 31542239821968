import { FormField } from '../../../../amrPipeline/common/FormField';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { Deal } from '../../../../../types/amr-pipeline/models/Deal';
import { collateralTypesAbbr } from '../../../../../constants/collateral-types';
import { constants, roles } from '../../../../../constants';
import { formatUtils, moneyUtils } from '../../../../../utils';
import { ShowFor } from '../../../../access';

const { emptyPlaceholder } = constants;

interface Props {
    deal: Deal;
}

export const Information = ({ deal }: Props) => {
    const {
        legalName,
        ticker,
        collateralType,
        currencyCode,
        originalBalance,
        currentBalance,
        factor,
        status,
        redeemed,
    } = deal;

    return (
        <SectionContent title="Information">
            <FormField title="Deal Name" value={legalName ?? emptyPlaceholder} tooltip={legalName || ""} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Ticker" value={ticker ?? emptyPlaceholder} tooltip={ticker || ""} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Collateral Type" value={collateralTypesAbbr[collateralType] ?? emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Currency" value={currencyCode ?? emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Orig. Balance" value={originalBalance ? moneyUtils.money(originalBalance) : emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Cur. Balance" value={currentBalance ? moneyUtils.money(currentBalance) : emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <FormField title="Factor" value={factor ? formatUtils.formatFactor(factor) : emptyPlaceholder} className="data-item-row-full-height data-item-row-title-md" />
            <ShowFor roles={roles.getWithout(roles.dealsAccess(), [...roles.bd(), ...roles.seller(), roles.Media])}>
                <FormField title="Status" value={formatUtils.formatDealStatus(status)} className="data-item-row-full-height data-item-row-title-md" />
            </ShowFor>
            <FormField title="Redeemed" value={formatUtils.formatBooleanWithPlaceholder(redeemed)} className="data-item-row-full-height data-item-row-title-md text-capitalize" />
        </SectionContent>
    );
};
