import { EmptyPlaceholder, OnHoverTooltip } from '../../../common';
import { Contact } from '../../../../types/amr-pipeline/models/Contact';
import { Table } from '../../../bidding/common/table';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { nameUtils } from '../../../../utils';
import { constants } from '../../../../constants';
import IconSVG from '../../../../styles/svg-icons';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';

interface Props {
    members?: Contact[];
    primaryContact?: Contact;
    collateralManagerReferenceName?: string;
}

const fullName: IColumnDefinition<Contact> = {
        columnKey: 'fullName',
        renderColumnHeaderContent: () => 'Full Name',
        renderColumnContent: ({ firstName, lastName }) => {
            const fullName = nameUtils.getFullName(firstName, lastName);

            return (
                <div className="text-ellipsis">
                    <OnHoverTooltip overlay={fullName} disabled={!(firstName || lastName)}>
                        <span>{fullName}</span>
                    </OnHoverTooltip>
                </div>
            );
        },
        className: 'data-list-cell-lg',
    };
const nameOfGroup: IColumnDefinition<string> = {
    ...fullName,
    renderColumnContent: (name) => name,
}

const memberColumnsDefinitions: IColumnDefinition<Contact>[] = [
    {
        columnKey: 'iconContact',
        renderColumnHeaderContent: () => null,
        renderColumnContent: (member, { primaryContact }) => (
            <>
                {member.email === primaryContact?.email && (
                    <OnHoverTooltip overlay="Primary contact">
                        <IconSVG name="primary-contact" width={16} height={16} />
                    </OnHoverTooltip>
                )}
            </>
        ),
        className: 'data-list-cell-xxs padding-l-0 text-center',
    },
    {
        columnKey: 'email',
        renderColumnHeaderContent: () => 'Email',
        renderColumnContent: member => (
            <div className="text-ellipsis">
                <OnHoverTooltip overlay={member.email}>
                    <a href={`mailto:${member.email}`}>{member.email}</a>
                </OnHoverTooltip>
            </div>
        ),
        className: 'data-list-cell-xl',
    },
    {
        columnKey: 'phone',
        renderColumnHeaderContent: () => 'Phone',
        renderColumnContent: member => member.mobilePhoneNumber || constants.emptyPlaceholder,
        className: 'data-list-cell-md',
    },
];

const memberColumns = [fullName, ...memberColumnsDefinitions].map(c => new ColumnBuilder(c));
const memberCollapsibleColumns = [fullName, ...memberColumnsDefinitions].map(c => new ColumnBuilder({ ...c, renderColumnHeaderContent: () => null }));
const memberHeadColumns = [new ColumnBuilder(nameOfGroup), ...memberColumnsDefinitions.map(c => new ColumnBuilder({ ...c, renderColumnContent: () => null}))];

const MembersTab = ({ members, collateralManagerReferenceName, primaryContact }: Props) => {
    const withAssignedCollateralManager = (member: Contact) =>
        member.assignedCollateralManagerReferenceName === collateralManagerReferenceName;

    const isListGrouped = members?.some(withAssignedCollateralManager) && !members.every(withAssignedCollateralManager);

    const renderCollapsibleList = () => {
        const allMembers = (members || []).reduce(
            (acc: { assignedContacts: Contact[]; otherContacts: Contact[] }, member) => {
                const list: keyof typeof acc = withAssignedCollateralManager(member)
                    ? 'assignedContacts'
                    : 'otherContacts';

                return { ...acc, [list]: [...acc[list], member] };
            },
            { assignedContacts: [], otherContacts: [] },
        );

        const { assignedContacts, otherContacts } = allMembers;
        const assignedTitle = `Assigned Contacts (${assignedContacts.length})`;
        const otherTitle = `Other Contacts (${otherContacts.length})`;

        const allRows = [assignedTitle, otherTitle];

        const renderCollapsibleItem = (listItem: string) => {
            return (
                <Table
                    dataItems={listItem === assignedTitle ? allMembers.assignedContacts : allMembers.otherContacts}
                    columns={memberCollapsibleColumns}
                    headerVisible={false}
                />
            );
        };

        return (
            <Table
                className="data-list-striped"
                dataItems={allRows}
                columns={memberHeadColumns}
                renderCollapsibleItem={renderCollapsibleItem}
                collapsible
            />
        );
    };

    const renderPlainList = () => {
        return (
            <Table
                dataItems={members}
                columns={memberColumns}
                createSecurityCustomArgs={() => ({ primaryContact })}
                className="data-list-striped"
            />
        )
    };

    const renderList = () => (isListGrouped ? renderCollapsibleList() : renderPlainList());

    if (!members?.length) {
        return <EmptyPlaceholder text="There are no platform registered users in this company." />;
    }

    return renderList();
};

export default MembersTab;
