import { Formik } from 'formik';
import { dealsActions } from '../../../../../actions/deals.actions';
import { RequestState } from '../../../../../constants/request-state';
import { useAppDispatch } from '../../../../../effects/useAppDispatch';
import { AmrClass } from '../../../../../types/amr-pipeline/models/AmrClass';
import { SettlementInstructions } from '../../../../../types/amr-pipeline/models/SettlementInstructions';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { TextField } from '../../../../common/form-fields/TextField';
import { Preloader } from '../../../../common';
import { Popup, PopupBody, PopupFooter } from '../../../../controls';
import { useSettlementInstructions } from '../../../../../effects/useSettlementInstructions';
import { yup } from '../../../../../validation/yup';

interface EditDealersPopupProps {
    onClose: () => void;
    selectedClass: AmrClass;
}

const validationSchema = yup.object().shape({
    cashSettlementAccountAbaNumber: yup.string().length(9, 'ABA Number should be a 9-character string.').nullable(),
    cashSettlementAccountSWIFT: yup
        .string()
        .min(8, 'SWIFT should be 8 or 11-character string.')
        .max(11, 'SWIFT should be 8 or 11-character string.')
        .nullable(),
    cashSettlementAccountAccountNumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .max(12, 'Account Number should be an up to 12-digits number.')
        .nullable(),
    bondSettlementAccountDTCParticipant: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .max(4, 'DTC Participant should be an up to 4-digit number.')
        .nullable(),
    bondSettlementAccountAgentNumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .max(6, 'Agent Number should be an up to 6-digits number.')
        .nullable(),
    bondSettlementAccountInstitutionalId: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .max(10, 'Institutional Id should be an up to 10-digits number.')
        .nullable(),
    bondSettlementAccountAccountNumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .max(12, 'Account Number should be an up to 12-digits number.')
        .nullable(),
});

export const SettlementInstructionsPopup = ({ selectedClass, onClose }: EditDealersPopupProps) => {
    const dispatch = useAppDispatch();

    const { requestState, settlementInstructions } = useSettlementInstructions({
        dealReferenceName: selectedClass.dealReferenceName,
        transactionReferenceName: selectedClass.transactionReferenceName,
        classReferenceName: selectedClass.referenceName,
    });

    const handleSave = (value: SettlementInstructions) => {
        dispatch(
            dealsActions.saveSettlementInstructionsRequest(
                selectedClass.dealReferenceName,
                selectedClass.referenceName,
                value,
            ),
        );
        onClose();
    };

    return (
        <Popup
            title={
                <>
                    Edit Settlement Instructions
                    <span className="name-tag text-ellipsis">Class {selectedClass.name}</span>
                </>
            }
            onClose={onClose}
            modalClass="edit-settlement-instructions-popup"
        >
            <Formik validationSchema={validationSchema} initialValues={settlementInstructions!} enableReinitialize onSubmit={handleSave}>
                {({ isSubmitting, submitForm }) => (
                    <Preloader inProgress={isSubmitting || requestState === RequestState.request}>
                        <PopupBody>
                            <div className="data-item-row-wrap">
                                <div className="data-item-row-col">
                                    <SectionContent title="Cash Settlement Account" className="data-item-column-cnt">
                                        <TextField
                                            name="cashSettlementAccountBank"
                                            label="Bank"
                                            placeholder="Cash Settlement Account"
                                        />
                                        <TextField
                                            name="cashSettlementAccountAbaNumber"
                                            label="ABA Number"
                                            placeholder="ABA Number"
                                        />
                                        <TextField name="cashSettlementAccountSWIFT" label="SWIFT" placeholder="SWIFT" />
                                        <TextField
                                            name="cashSettlementAccountAccountNumber"
                                            label="Account Number"
                                            placeholder="Account Number"
                                        />
                                        <TextField
                                            name="cashSettlementAccountAccountName"
                                            label="Account Name"
                                            placeholder="Account Name"
                                        />
                                        <TextField
                                            name="cashSettlementAccountReference"
                                            label="Reference"
                                            placeholder="Reference"
                                        />
                                    </SectionContent>
                                </div>
                                <div className="data-item-row-col">
                                    <SectionContent title="Bond Settlement Account" className="data-item-column-cnt">
                                        <TextField name="bondSettlementAccountBank" label="Bank" placeholder="Bond Settlement Account" />
                                        <TextField
                                            name="bondSettlementAccountDTCParticipant"
                                            label="DTC Participant"
                                            placeholder="DTC Participant"
                                        />
                                        <TextField
                                            name="bondSettlementAccountAgentNumber"
                                            label="Agent Number"
                                            placeholder="Agent Number"
                                        />
                                        <TextField
                                            name="bondSettlementAccountInstitutionalId"
                                            label="Institutional Id"
                                            placeholder="Institutional Id"
                                        />
                                        <TextField
                                            name="bondSettlementAccountAccountNumber"
                                            label="Account Number"
                                            placeholder="Account Number"
                                        />
                                        <TextField
                                            name="bondSettlementAccountAccountName"
                                            label="Account Name"
                                            placeholder="Account Name"
                                        />
                                    </SectionContent>
                                </div>
                            </div>
                            <SectionContent title="Contacts" className="data-item-column-cnt data-item-general-cols">
                                <div className="data-item-row-col">
                                    <TextField name="trusteeContact" label="Trustee" placeholder="Trustee" />
                                </div>
                                <div className="data-item-row-col">
                                    <TextField name="amrAgentContact" label="AMR Agent" placeholder="AMR Agent" />
                                </div>
                            </SectionContent>
                        </PopupBody>
                        <PopupFooter>
                            <button type="button" className="btn btn-ghost" onClick={onClose}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-main" onClick={submitForm}>
                                Save
                            </button>
                        </PopupFooter>
                    </Preloader>
                )}
            </Formik>
        </Popup>
    );
};
