import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';

export const DirectBiddingDisclaimerPopupText: React.FC = () => {
    return (
        <>
            <h2>DirectBidding&#8482; and All-to-All Trading Protocols Terms and Conditions</h2>
            <p>The DirectBidding&#8482; and All-to-All trading protocols (the "Trading Protocols") are part of the KopenTech Alternative Trading System (the “ATS”). The use of Trading Protocols by you ("You") is governed by these Terms and Conditions.</p>
            <p>The use of the ATS is governed by the rules and procedures that govern the use of the ATS for all users  (the “ATS Rulebook”), available on the KopenTech platform publicly for review and can be accessed <Link to={routes.ATSRulebook} target="_blank" rel="noreferrer noopener">here</Link>. You must confirm the receipt of the ATS Rulebook and agree to abide by the terms and conditions of the ATS.</p>
            <p>These Trading Protocols are designed to facilitate the matching of buyers and sellers on the KopenTech ATS. As a user of the ATS, You agree to comply with these Terms and Conditions, Trading Protocols, and the following rules:</p>

            <h2>1. Display of Bids</h2>
            <p>These Trading Protocols govern the process for posting lists of securities for sale, or BWICs, for CLOs that are (or will become) available for bidding. BWICs posted using the Trading Protocols will be marked with a special icon clearly indicated on the KopenTech ATS. For each BWIC available via the Trading Protocols, You can submit a bid directly and anonymously to the seller and receive real-time, automated feedback from the ATS. KopenTech only provides feedback to You during the “Bidding Window”, that is defined as the period of time specified by the seller on the BWIC announcement. This feedback shows Your standing among the other bidders. KopenTech ATS has pre-defined feedback choices that are automatically sent to a bidder once a bid is submitted.</p>
            <p>You will see Your bid position solely in the context of other users of the Trading Protocols. Depending on the chosen BWIC rules, You will receive real-time feedback as bids entered by You and other users are collected. At the end of the Bidding Window, the seller has the option to trade the bond to You.</p>

            <h2>2. BWIC Exclusivity</h2>
            <p>When posting a BWIC on the KopenTech ATS, You commit to not redistribute the same BWIC either through other available technology platforms and/or directly to broker dealers at the same time.</p>

            <h2>3. Bidding</h2>
            <p>On the BWIC Monitor, click on the “Bid” button to enter a bid for any bond(s) that are marked as available via the Trading Protocols. Bids are irrevocable upon submission. Your last submitted bid remains firm and executable until [30] minutes after the bidding window closes. Once that time lapses, if there is no action by the seller, the BWIC will close and the ATS will automatically send out information regarding the BWIC.</p>

            <h2>4. Cancelling</h2>
            <p>You cannot cancel Your bid. Once a bid is entered, a pop-up will appear asking You to confirm Your bid. This is done to prevent fat finger errors. See Section 5 for further information.</p>

            <h2>5. Determination of Winning Bid</h2>
            <p>The seller will evaluate the bids received from the BWIC participants. Sellers make their own determination about whether to sell the bonds based solely upon their own criteria.</p>
            <p>KopenTech is not acting as Your adviser or agent. Prior to making any investment decision or entering into any transaction, You should determine, without reliance on KopenTech or any of its affiliates, the economic risks and merits, as well as the legal, tax and accounting characterizations and consequences of any investment or transaction, and independently determine that You are able to assume these risks. By submitting Your bids, You acknowledge that (a) KopenTech is not in the business of providing legal, tax or accounting advice, (b) You understand that there may be legal, tax or accounting risks associated with any investment or transaction, (c) You should receive legal, tax and accounting advice from advisers with appropriate expertise to assess relevant risks. In addition, KopenTech will provide all bidders with the post-trade color, meaning the second-best bid received during the bidding period. By submitting Your bid, You acknowledge that post-trade color might deviate significantly from Your bid and the trading level and that this information does not affect the validity of Your bid. You are responsible for Your own independent research and assertion of value before deciding to place a bid on any security. All bids made and matched on the ATS are considered final transaction agreements and it is expected that subsequent transactions will be made based on the bids and matches.</p>

            <h2>6. Trading</h2>
            <p>The seller has the option to sell a security to You. Trade affirmation by You is required to complete the trade and must be performed by You within 30 minutes after receipt of notification from Seller via the ATS. Trades may only be declined in the case of technical errors. If a technical error occurs, You must promptly contact KopenTech at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a> in an effort to resolve the matter. Trades may not be rejected without valid reason and failure to affirm trades may result in revoked access to KopenTech Trading Protocols. KopenTech will have no liability for rejected trades.</p>
            <p>Trade settlement is conducted by KopenTech Capital Markets LLC, a registered broker-dealer and FINRA member and clearing is performed by a third-party clearing agent. The trade commission is set by KopenTech and can be accessed on the BWIC Trading Protocol. Trade commissions are subject to change from time to time and any changes will be communicated to You. You will be responsible any additional fees assessed by the clearing firm for any non-standard settlement or delayed settlement of trades.</p>

            <h2>7. Information and Activity</h2>
            <p>Data and information provided to You through the Trading Protocols is gathered from sources that KopenTech regards to be reliable, but KopenTech is not responsible for the accuracy of any data or information that it makes available to You.</p>
            <p>KopenTech may collect Your data to display aggregated analytics to platform users. KopenTech monitors ATS activity and collects trade data for analytical purposes. In addition, while Your specific bid information will not be divulged to other bidders, the Trading Protocols analyze the bid data You enter to inform You and other bidders of their relative standing, which can include the settlement agent, bidders and broker-dealers. </p>
            <p>You acknowledge that ATS and/or its affiliates and service providers own all rights, title and interest, including without limitation all intellectual property rights, in performance of the services provided herein, the ATS, and all trademarks and trade names used in connection with the services provided, including all data entered into or produced by or for the ATS, including without limitation all bid and ranking data (such data collectively, “KopenTech ASP Data”).</p>

            <h2>8. Limitations on Liability and Use</h2>
            <p>KopenTech reserves the right to change the these Terms and Conditions at any time. KopenTech also reserves the right, in its sole discretion, to revoke Your access to the Trading Protocols or to otherwise make the Trading Protocols unavailable to You.</p>

            <h2>9. No Physical Settlement</h2>
            <p>No transaction matched through the Trading Protocols on the ATS may be physically settled. The settlement agent has no responsibility for processing physical settlements. It is the responsibility of the seller of securities not to list such bonds via Trading Protocols.</p>

            <h2>10. No 144A/Reg S Conversions</h2>
            <p>No transaction matched through the Trading Protocols on the ATS may be converted from 144A to Reg S or vice versa. Buyers are providing firm bids for the securities listed in the format identified by a seller.</p>

            <h2>11. Authorization</h2>
            <p>You agree that You are responsible for the actions of any persons authorized to trade or act or act on Your behalf. You agree to maintain updated authorized trader list on file with KopenTech Capital Markets LLC.</p>

            <h2>12. Commission Sharing</h2>
            <p>KopenTech Capital Markets LLC and the clearing agent share commissions collected by the clearing agent to execute and settle transactions matched on the ATS. There is no additional cost to You because of this arrangement.</p>
        </>
    )
}
